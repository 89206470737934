import React from 'react';
import { Typography } from '@material-ui/core';

import { ManageDateField, ManageTimeField, AlertingComponent, DateUtils } from 'react-frontend-utils'

import { DateConversions } from '../utils/DateConversions'
import { ThemeColors } from '../Theme';

/**
 * This components wraps a ManagedDateField and ManagedTimeField in order to work exclusively with LuxonDates. The date and time
 * format is chosen based on the locale (US, or international).
 * 
 * The user can supply the following props:
 *  timezone (String): The timezone to use for Luxon date time objects. Default if not supplied is "America/New_York"
 *  initialDate (luxon DateTime object): The initial date/time to display. If not supplied, the current date/time is used.
 *  label (String): The label (each component is appended with "Date" or "Time")
 *  hideTime (boolean): If true, the time field is hidden. Default is false.
 * 
 *  onDateChange (function(luxonDateTime)): A callback function that is called when the date is changed. 
 *                           The function is passed a Luxon DateTime object in the configured timezone.
 * 
 * 
 *  onDateParseError (function(String, String)): A callback function that is called when there is a parse error in the date or time field.
 *                      The function is passed an error title and message
 * 
 * The user can call the following functions:
 *  set(luxonDateTime): Set the date and time to the given Luxon DateTime object
 */

export class LuxonDatePicker extends AlertingComponent {
    
    
    _dateRef = React.createRef();
    _timeRef = React.createRef();

    _minYear = (new Date()).getFullYear() - 3;  //3 years ago
    _maxYear = (new Date()).getFullYear() + 10; //10 years from now

    _timezone;
    _isUS;

    _initialDateString;     // the initial date string, in us or international format
    _initialTimeString;     // the initial time string, in 12 or 24 hour format

    _currentDateString;     // the current date string in as a json date string
    _currentTimeString;     // the current time string in 24 hour format

    

    static toJsonDateStr(luxonDate) {
        return luxonDate.toISODate().split("T")[0];
    }


    constructor(props) {
        super(props);
        
        this._isUS = DateConversions.isUSDateFormat();
        
        this._timezone = props.timezone ? props.timezone : "America/New_York";

        let initialDate;
        if (!props.initialDate) // no start date? use today
            initialDate = DateConversions.now(this._timezone);
        else
            initialDate = props.initialDate;

        const timeString = DateConversions.localTimeStringFromLuxonDateTime(initialDate);

        // our initial date string is now in the timezone    
        this._initialDateString = DateConversions.toDateStr(initialDate, false);
        this._currentDateString = LuxonDatePicker.toJsonDateStr(initialDate);  // our current date string in json format, no time

        // our initial time string, convert to am/pm if US
        this._initialTimeString = this._isUS ? DateUtils.toFriendlyTimeString(timeString) : timeString;
        this._currentTimeString = timeString;  // keep our current time string in 24 hour format
    }

    set(date) {
        const dateStr = DateConversions.toDateStr(date, false);
        this._currentDateString = LuxonDatePicker.toJsonDateStr(date);

        const timeString = DateConversions.localTimeStringFromLuxonDateTime(date);
        const timeStr = this._isUS ? DateUtils.toFriendlyTimeString(timeString) : timeString;
        this._currentTimeString = timeString;

        this._dateRef.current.set(dateStr);
        this._timeRef.current.set(timeStr);
    }


    // When the date field changes, we accept the date. The function passes jsonDateString which is a the user selected (local) date in their timezone
    _dateFieldChanged = (json, jsonDateString) => {
        this._dateRef.current.accept();  
        this._currentDateString = jsonDateString;    
        this._callbackOnChange();
    }

    _timeFieldChanged = (json, timeString) => {
        this._timeRef.current.accept();
        this._currentTimeString = DateUtils.parseTimeString(timeString);  // convert to 24 hour time, if needed
        this._callbackOnChange();
    }

    // If there is a parse error, show an alert
    _parseError = (label, error) => {
        if (this.props.onParseError)
            this.props.onParseError("Error in Field \"" + label + "\"", error);
    }

    _callbackOnChange = () => {
        let dateStr = this._currentDateString + "T" + this._currentTimeString + ":00";      // create our full date string with time

        // Convert the jsonDateString to a luxonDateTime object in the configured timezone
        const luxonDate = DateConversions.jsonDateStringToLuxonDateTime(dateStr, this._timezone);   

        if (this.props.onDateChange)
            this.props.onDateChange(luxonDate);
    }


    render() {

        if (this.props.isReadOnly) {
            return (
                <Typography align='center' variant='h6'>{this.props.label + ": " + this._initialDateString + " at " + this._initialTimeString} </Typography>
            );
        }
        
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20}}>

                <ManageDateField ref={this._dateRef} 
                                    label={this.props.label + " Date"} 
                                    json="startDate"
                                    dateFormat={this._isUS ? DateUtils.DateFormatType.US : DateUtils.DateFormatType.ISO8601 }
                                    initialValue={this._initialDateString} 
                                    minYear={this._minYear}
                                    maxYear={this._maxYear}
                                    calendarColor={ThemeColors.calendarColor}
                                    onFieldChange={this._dateFieldChanged} 
                                    onParseError={this._parseError} />

                {!this.props.hideTime ?
                    <ManageTimeField  label={this.props.label + " Time"} 
                                        ref={this._timeRef}
                                        json="startTime"
                                        clockColor={ThemeColors.clockColor} 
                                        twelveHour={this._isUS}
                                        initialValue={this._initialTimeString} 
                                        onFieldChange={this._timeFieldChanged} 
                                        onParseError={this._parseError}/> 
                    : null
                }

            </div>
            
        );
    }
    
}