// NewCalendarDialog.js
import React, { Component } from 'react';
import { Typography, Box, Container, Popover, RadioGroup, FormControlLabel, Radio, TextField, Button } from '@material-ui/core';

/**
 * Popup to select new Resource/Service, or edit an existing, and set/change its name, category, and description.
 * 
 * caller should pass the following props:
 *    resource: the resource object to edit (ignored if isNew is true)
 *    isOpen (boolean): True if the popover is open, false otherwise
 *    isNew (boolean): True if the resource is new, false if it is being edited
 *    cancelCallback (function): A callback function that is called when the user clicks "Cancel"
 *    okCallback (function): A callback function that is called when the user clicks the ok button, passing the type, name and description
 *
 */
export class NewEditResourcePopover extends Component {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
        }
    };

    state = {
        type: 'Service',
        name: null,
        category: null,
        description: null
    };

    _getName = () => {
        let name = this.state.name;
        if (name == null) {
            if (this.props.isNew || !this.props.resource)
                name = '';
            else
                name = this.props.resource.name;
        }
        return name;
    }

    _getCategory = () => {
        let category = this.state.category;
        if (category == null) {
            if (this.props.isNew || !this.props.resource)
                category = '';
            else
                category = this.props.resource.category;
        }
        return category;
    }

    _getDescription = () => {
        let description = this.state.description;
        if (description == null) {
            if (this.props.isNew || !this.props.resource)
                description = '';
            else
                description = this.props.resource.description;
        }
        return description;
    }

    _handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    _okClicked = () => {
        this.props.okCallback(this.state.type, this._getName().trim(), this._getCategory().trim(), this._getDescription());
        this.setState({ name: null, category: null, description: null});       // clear for next time
    } 

    _cancelClicked = () => {
        this.props.cancelCallback();
        this.setState({ name: null, category: null, description: null});       // clear for next time
    }

    render() {

        const name = this._getName();
        const description = this._getDescription();
        const category = this._getCategory();

        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 700
        if (maxWidth > 700)
            maxWidth = 700;

        const okEnabled = name && name.length > 0;

        const title = this.props.isNew ? "New Calendar" : "Edit " + (this.props.resource ? this.props.resource.name : "Calendar");

        return (
            <Popover open={this.props.isOpen}
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}>
            
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold', marginBottom: 20}}>{title}</Typography>  

                    {this.props.isNew ? 
                        <RadioGroup name="type" value={this.state.type} onChange={this._handleChange} style={{marginBottom: 20}}>
                            <FormControlLabel value="Resource" control={<Radio color='primary'/>} label="Resource" />
                            <FormControlLabel value="Service" control={<Radio color='primary' />} label="Service" />
                        </RadioGroup>
                        : null
                    }
                    <TextField name="name" label="Name" value={name} onChange={this._handleChange} fullWidth variant='outlined' InputLabelProps={{ shrink: true}} style={{marginBottom: 20}}/>
                    <TextField name="category" label="Category" value={category} onChange={this._handleChange} fullWidth variant='outlined' InputLabelProps={{ shrink: true}} style={{marginBottom: 20}}/>
                    <TextField name="description" label="Description" multiline={true} minRows={6} value={description} onChange={this._handleChange} variant='outlined' fullWidth InputLabelProps={{ shrink: true}} style={{marginBottom: 20}}/>
                     
                    <Container style={this.styles.container}>    
                        <Button onClick={this._okClicked} color="primary" disabled={!okEnabled} variant="outlined" style={this.styles.button}>{this.props.isNew ? "Create" : "Update"}</Button>
                        <Button onClick={this._cancelClicked} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>

            </Popover>
        );
    }
}