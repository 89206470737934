import { ThemeColors } from '../Theme';
import { Global } from './Global'
import { Permissions } from 'react-frontend-utils'

export class Resource {

    id;      
    updateCount;        // Used to detect changes to the slot - do not modify on front end
    type;               //Subclass type, for Jackson       
    group;
    name;
    description;
    category;
    color;
    isoCurrency;


    constructor(json) {
        if (json) {
            this.id = json.id;
            this.type = json.type;
            this.updateCount = json.updateCount;
            this.group = json.group;
            this.name = json.name;
            this.description = json.description;
            this.category = json.category;
            this.color = json.color ? json.color : ThemeColors.appBarBackground;
            this.isoCurrency = json.isoCurrency;
        }
    }

    initNew(name, category, description, color) {
        this.name = name;
        this.updateCount = 0;
        this.description = description;
        this.category = category;
        this.group = Global.getLastDatabase();
        this.color = color;
        this.order = 0; 
    }


     // Create a new Resource object with the user's current group
     static requestNew(name, category, description, color) {
        const resource = new Resource();
        resource.type = "Resource"
        resource.initNew(name, category, description, color);
        return resource;
    }

    static getNextColor(resourceCount) {
        const colorIndex = resourceCount % ThemeColors.resourceColors.length;
        return ThemeColors.resourceColors[colorIndex];
    }

    // Get only the Resources, and return them in a 2D array, grouped by category
    static groupResourcesByCategory(resources, type) {
        if (!resources || resources.length === 0)
            return [];

        const resourcesOnly = resources.filter((r) => r.type === type);
        const categories = resourcesOnly.reduce((acc, resource) => {
            if (!acc[resource.category])
                acc[resource.category] = [];
            acc[resource.category].push(resource);
            return acc;
        }, {}); // Provide an initial value for the accumulator

        const categoryArray = Object.keys(categories).map(key => categories[key]);

        // Sort the categories by name
        categoryArray.sort((a, b) => {
            if (a[0].category < b[0].category)
                return -1;
            if (a[0].category > b[0].category)
                return 1;
            return 0;
        });

        // Sort the resources within each category by name
        categoryArray.forEach((category) => {
            category.sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            });
        }); 

        return categoryArray;
    }

    toFullCalendarResource() {
        return {
            id: this.id,
            title: this.name,
            color: this.color
        }
    }



    canEdit() {
        return Global.user.hasPermissionTo(Permissions.MANAGE_RESOURCES);      // only resource managers can edit resources
    }

}