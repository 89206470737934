import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { RestComponent } from 'react-frontend-utils' 

import { Global } from '../models/Global'
import { ThemeColors } from '../Theme'
import { Booking } from '../models/Booking'
import { PatronServiceCalendarSlot } from '../components/PatronServiceCalendarSlot'
import { PatronServiceCalendarButton } from '../components/PatronServiceCalendarButton'
import { Typography, Button } from '@material-ui/core';
import { DateConversions } from '../utils/DateConversions';


/**
 * Page 1 of the Booking process. Allows the user to select a date and time for the booking. Uses one of two calendar components, depending on the service's
 * minimum slot time. If the service uses a slot calendar, the user can select a time slot. If the service uses a button calendar, the user can select a time
 * by clicking on a button.
 * 
 * The following props are required:
 *  - service: the Service object that the user is booking
 *  - prevPageCallback: a callback that returns to the previous page
 *  - nextPageCallback: a callback that is called when the user has selected a booking slot
 */
export class BookServicePage1 extends RestComponent {


    _prevPageCallback;
    _nextPageCallback;
    _calendarRef = React.createRef();
    _timezone = Global.getTimezone();

    constructor(props) {
        super(props);
    
        this._prevPageCallback = props.prevPageCallback;  //callback that returns to PatronBooking
        this._nextPageCallback = props.nextPageCallback;  //callback when Booking slot is created
        this.state.selectedBooking = null;
    }
    
    
    componentDidMount() {
        super.componentDidMount(); 
        window.addEventListener("popstate", this._prevPageCallback);
    }
   
    componentWillUnmount() {
        super.componentWillUnmount();       
        window.removeEventListener("popstate", this._prevPageCallback);
    }

    //--------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------- CALENDAR CALLBACK -----------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------------------

    // Fetches a list of Blocks and Availabilities for the given date range for the service. Blocks are unavailable times either because there
    // is a Booking (and the service does not allow overlapping bookings) there are no available resources. Availabilities are the expanded 
    // available times for the service, outside the business hours and the season start/end dates.
    _fetchSlots = (jsonDateStart, jsonDateEnd, serviceID, successCallback, failureCallback) => {

        // If we are using the button calendar, we tell the server to include all the individual slots on each Resource, not just when they overlap. Because
        // the button calendar uses 15 minute increments, we need to check against the entire button duration
        const allResources = this.props.service.usesSlotCalendar() ? "" : "&allResources=true";

        this.secureJSONFetch("/patron/services/" + serviceID + "/slots?fromDate=" + jsonDateStart + "&toDate=" + jsonDateEnd + allResources, {}, 
                             (response) => successCallback(response), (error) => failureCallback(error)); 

    }


    _setBooking = (booking) => {
        console.log("Booking set: ", booking);
        
        if (this._calendarRef.current.setBooking)
            this._calendarRef.current.setBooking(booking);
        this.setState({selectedBooking: booking});
    }

    _dateSelected = (startTime, endTime) => {

        if (!startTime || !endTime) {
            this._setBooking(null);
            return;
        }

        const newBooking = Booking.createNew(startTime, endTime, this.props.service);
        this._setBooking(newBooking);
    }


    // Extend slot time by 1 increment, if it won't exceed the max booking time
    _extendSlotTime = () => {

        const booking = this.state.selectedBooking; 
        if (!booking)
            return;

        const maxBookingTimeMin = this.props.service.serviceParams.incrementsMinutes * this.props.service.serviceParams.maxIncrements;
        if (DateConversions.duration(booking.start, booking.end) < maxBookingTimeMin * 60) {
            const newEndTime = booking.end.plus({minutes: this.props.service.serviceParams.incrementsMinutes});
            booking.end = newEndTime;
            this._setBooking(booking);
        }
        else 
            this.showConfirmAlert("Maximum Booking Time Reached", "Your booking can only be " + maxBookingTimeMin + " minutes long", 'red');
    }

    
    render() {
       
        const isMobile = window.innerWidth < 600;

        const useSlotCalendar = this.props.service.usesSlotCalendar();

        if (this.state.isBusy)
            return this.getBusyComponent('center', {marginTop: 20});

        let msg;
        if (useSlotCalendar) {
            msg = "To select date and time, choose a day and then select a time slot. Each time slot is " + this.props.service.serviceParams.incrementsMinutes + " minutes long.";        
            if (this.props.service.serviceParams.maxIncrements > 1)
                msg += " You can book up to " + this.props.service.serviceParams.maxIncrements + " consecutive slots. Click on the first slot and then drag down as many slots as you want to book.";
            else
                msg += " Click on the time slot to select it.";

            if (this.props.service.serviceParams.schedulingInterval === 30)
                msg += " Note that your booking must start on the half-hour.";
            else if (this.props.service.serviceParams.schedulingInterval === 60)
                msg += " Note that your booking must start on the hour.";

            msg += " Greyed out slots are not available.";
        }
        else {
            if (this.props.service.serviceParams.maxIncrements === 1)
                msg = "Click on any available green slot to select your booking time.";
            else
                msg = " You can book up to " + this.props.service.serviceParams.maxIncrements + " consecutive slots. Click on the consecutive slots to select those times.";
            
        }

        return (
            <Fragment>
                {this.getConfirmAlertComponent()}

    
                <Button disabled={!this.state.selectedBooking} variant='contained' onClick={() => this._nextPageCallback(this.state.selectedBooking)} 
                        style={{backgroundColor: this.state.selectedBooking ? 'green' : ThemeColors.veryLightGray, color: 'white', float: 'right', marginLeft: 10}}>Proceed to Book</Button>
                
                <Typography variant='h6'>{"Book " + this.props.service.name}</Typography>
                <Typography variant={isMobile ? 'body2' : 'body1'} style={{marginBottom: 30}}>{msg}</Typography>

                <div style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: 1024}}>
                    {useSlotCalendar ?
                        <PatronServiceCalendarSlot ref={this._calendarRef} 
                                                            key={this.state.timezone /* force re-render when timezone changes, to fix the "today" indication for the new timezone */}  
                                                            initialTimezone={this._timezone} 
                                                            fetchSlots={this._fetchSlots}
                                                            dateSelectCallback={this._dateSelected}
                                                            extendSlotTime={this._extendSlotTime}
                                                            loadingCallback={this._calendarBusy}
                                                            service={this.props.service}
                                                            />
                        :
                        <PatronServiceCalendarButton ref={this._calendarRef} 
                                                            key={this.state.timezone /* force re-render when timezone changes, to fix the "today" indication for the new timezone */}  
                                                            initialTimezone={this._timezone} 
                                                            fetchSlots={this._fetchSlots}
                                                            dateSelectCallback={this._dateSelected}
                                                            loadingCallback={this._calendarBusy}
                                                            service={this.props.service}
                                                            />
                    }
                </div>
            </Fragment>
        );
    }

}



export default withCookies(withRouter(BookServicePage1));

