import { DateTime, Interval } from 'luxon';
import { DateUtils } from 'react-frontend-utils';

export class DateConversions {


    static isUSDateFormat() {
       return Intl.NumberFormat().resolvedOptions().locale === 'en-US';  //is the locale US?
    }

    // Show the date as a string in the format "MM-DD-YYYY" or "DD-MM-YYYY" depending on the locale, in the local timezone, optional weekday
    static toDateStr(luxonDate, includeWeekday = false) {

        const weekdayStr = includeWeekday ? luxonDate.weekdayShort + ", " : "";
        const dateStr = String(luxonDate.day).padStart(2, "0");
        const monStr = DateUtils.monthNames[luxonDate.month -1];
        const yearStr = luxonDate.year;

        if (DateConversions.isUSDateFormat())
            return weekdayStr + monStr + "-" + dateStr + " " + yearStr;
        else
            return weekdayStr + dateStr + "-" + monStr + " " + yearStr;
    }

    // Convert a luxonDateTime object as into a UTC JsonDateString
    static luxonDateTimeToJsonDateString(luxonDateTime) {
        
        const isoString = luxonDateTime.toUTC().toISO();

        //remove the trailing .000Z
        return isoString.substring(0, isoString.length - 5);
    }

    // Convert a UTC JsonDateString to an Luxon DateTime, requires a full JsonDateString with time
    static utcJsonDateStringToLuxonDateTime(jsonDateStr) {
        const isoString = jsonDateStr + ".000Z";
        return DateTime.fromISO(isoString);
    }

    // Convert a JsonDateString in a specified timezone to a luxonDateTime object.  Requires a full JsonDateString with time
    static jsonDateStringToLuxonDateTime(jsonDateStr, inZone = "UTC") {
        return DateTime.fromISO(jsonDateStr + ".000", {zone: 'local'}).setZone(inZone, {keepLocalTime: true});
    }


    // Extract the local time string in the format "HH:MM" (24-hour time) from a luxonDateTime object
    static localTimeStringFromLuxonDateTime(luxonDateTime) {
        return String(luxonDateTime.hour).padStart(2, "0") + ":" + String(luxonDateTime.minute).padStart(2, "0");
    }


    // Get the date and time string for a luxon date time object, formatted for the user's locale
    static dateTimeStrComponents(luxonDate) {
        const isUS = DateConversions.isUSDateFormat();
        const dateStr = DateConversions.toDateStr(luxonDate, true);
        let timeString = DateConversions.localTimeStringFromLuxonDateTime(luxonDate);
        timeString = isUS ? DateUtils.toFriendlyTimeString(timeString) : timeString;

        return {dateStr: dateStr, timeStr: timeString};
    }

    static dateTimeStr(luxonDate) {
        const components = DateConversions.dateTimeStrComponents(luxonDate);
        return components.dateStr + " " + components.timeStr;
    }


    // Get a luxonDateTime object as the current time in the specified timezone
    static now(inZone = "UTC") {
        //Convert the local browser time to the specified timezone
        return DateTime.now().setZone(inZone);
    }
x

    // Get the hour and minue from a time string in the format "HH:MM" (24-hour time)
    static timeStringComponents(timeStr) {
        const components = timeStr.split(":");
        return {hour: parseInt(components[0]), minute: parseInt(components[1])};
    }

    static timeStringToMinutes(timeStr) {
        const components = DateConversions.timeStringComponents(timeStr);
        return components.hour * 60 + components.minute;
    }

    static minutesToTimeString(minutes) {
        const hour = Math.floor(minutes / 60);
        const minute = minutes % 60;
        return String(hour).padStart(2, "0") + ":" + String(minute).padStart(2, "0");
    }


    // Get the duration in seconds between two luxonDateTime objects, if the end is before the start, return 0
    static duration(start, end) {
        if (end < start)
            return 0;
        return Interval.fromDateTimes(start, end).length('seconds');
    }

    // Get a list of all Luxon DateTime supported timezones 
    static supportedTimezones() {
        const zones = Intl.supportedValuesOf('timeZone');
        return zones.filter(tz => tz.includes('/') && DateTime.local().setZone(tz).isValid)
    }

    // Get start and end times in minutes from midnight since the start of the day of the start time
    static dayMinuteOffset(start, end) {
        const startMin = start.hour * 60 + start.minute;
        const endMin = startMin + DateConversions.duration(start, end) / 60;
        return {startMin: startMin, endMin: endMin};
    }


    // Get the weekday index of the luxonDateTime object, 1=Sunday, 2=Monday, 3=Tuesday, 4=Wednesday, 5=Thursday, 6=Friday, 7=Saturday
    static weekdayIndex(date) {
        // luxon "weekday" property uses Monday=1, Tuesday=2, to Sunday=7
        return (date.weekday % 7) + 1;
    }

    // Get the date for the nth weekday in the month specified by date, the weekDay is 1=Sunday, 2=Monday, 3=Tuesday, 4=Wednesday, 5=Thursday, 6=Friday, 7=Saturday
    // the returned date's time remains the same as the input date
    static nthWeekdayOfMonth(date, nth, weekDay) {
        weekDay = weekDay - 1;  // convert to luxon weekday index
        if (weekDay === 0)
            weekDay = 7;

        const firstDay = date.set({day: 1});
        let diff = weekDay - firstDay.weekday;        // how many days to the first weekday
        if (diff < 0)
            diff += 7;  // if the desired weekday is before the first day of the month, add 7 days

        const day = diff + 7*(nth - 1);    // calculate the day of the month
        let nthWeekday = firstDay.plus({days: day});

        // if we are in a new month, return null
        if (nthWeekday.month !== date.month)
            return null;
        else
            return nthWeekday;
    }

    // Get the date for the last weekday in the month specified by date, the weekDay is 1=Sunday, 2=Monday, 3=Tuesday, 4=Wednesday, 5=Thursday, 6=Friday, 7=Saturday
    // the returned date's time remains the same as the input date
    static lastWeekdayOfMonth(date, weekDay) {
        weekDay = weekDay - 1;  // convert to luxon weekday index
        if (weekDay === 0)
            weekDay = 7;

        let lastDay = date.plus({months: 1}).set({day: 1}).minus({days: 1});  // goto the first day of the next month, then back 1 day

        // keep going back until we find the desired weekday
        while (lastDay.weekday !== weekDay)
            lastDay = lastDay.minus({days: 1});
        
        return lastDay;
    }

    // Given a luxon datetime, determine which weekday of the month it is (1=first, 2=second, 3=third, 4=fourth, 5=fifth)
    static whichWeekdayOfMonth(date) {
        const weekdayIndex = DateConversions.weekdayIndex(date);
        for (let i = 1; i <= 5; i++) {
            let nthWeekday = DateConversions.nthWeekdayOfMonth(date, i, weekdayIndex);
            if (nthWeekday && nthWeekday.equals(date))
                return i;
        }
    }


}