import React, { Component } from 'react';
import { Typography, Box, Container, Popover, RadioGroup, FormControlLabel, Radio, Button } from '@material-ui/core';


/**
 * This component is a popover that allows the user to select one of several slots
 * 
 * The user can supply the following props:
 * 
 * isOpen (boolean): True if the popover is open, false otherwise
 * slots (array of Slots): The slots to choose from
 * cancelCallback (function): A callback function that is called when the user cancels
 * okCallback (function): A callback function that is called when the user clicks OK, with the selected slot id as a parameter
 */
export class SlotSelectionPopover extends Component {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
        }
    };

    state = {
        selectedSlotId: null,
    }
 
    _handleRadioChange = (event) => {
        this.setState({ selectedSlotId: event.target.value });
    }


    // Use the selected slot from the state, if it exists, otherwise use the first slot in props
    _selectedSlotId = () => {
        return this.state.selectedSlotId ? this.state.selectedSlotId : (this.props.slots.length > 0 ? this.props.slots[0].id : null);
    }

    _cancel = () => {
        this.props.cancelCallback();
    }

    _ok = () => {
        this.props.okCallback(this._selectedSlotId());
    }

    render() {

        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 700
        if (maxWidth > 700)
            maxWidth = 700;

        const selectedSlotId = this._selectedSlotId();
        
        return (
            <Popover open={this.props.isOpen}
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}>
            
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold', marginBottom: 20}}>Select from Overlapping Events</Typography>  

                    {selectedSlotId ? 
                        <RadioGroup name="type" value={selectedSlotId} onChange={this._handleRadioChange} style={{marginTop: 20, marginBottom: 20}}>
                            {this.props.slots.map((slot, index) => 
                                <div key={index} style={{display: 'flex', justifyContent: 'left', gap: 20, alignItems: 'center'}}>
                                    <FormControlLabel value={slot.id} control={<Radio color='primary'/>} label={slot.title} style={{minWidth: 100}}/>
                                    <Typography variant="body2" style={{color: 'gray', fontStyle: 'italic'}}>{slot.description}</Typography>
                                </div>
                            )}
                        </RadioGroup>
                        : null
                    }
                     
                    <Container style={this.styles.container}>    
                        <Button onClick={this._ok} color="primary" variant="outlined" style={this.styles.button}>Select</Button>
                        <Button onClick={this._cancel} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>

            </Popover>
        );
    }
}