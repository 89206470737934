import React, { Component } from 'react';
import { Typography, Box, Container, Popover, RadioGroup, FormControlLabel, Radio, Button } from '@material-ui/core';

/**
 * This component is a popover that allows the patron to select a resource for their Booking
 * 
 * The user can supply the following props:
 * 
 * isOpen (boolean): True if the popover is open, false otherwise
 * resources (array of Resource): The resources to choose from
 * cancelCallback (function): A callback function that is called when the user cancels
 * okCallback (function): A callback function that is called when the user clicks OK, with the selected resource id as a parameter
 */
export class PatronSelectResourcePopover extends Component {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
        }
    };

    state = {
        selectedResourceId: null,
    }

    _cancel = () => {
        this.props.cancelCallback();
    }

    _ok = () => {
        this.props.okCallback(this.state.selectedResourceId);
    }

    _handleRadioChange = (event) => {
        this.setState({ selectedResourceId: event.target.value });
    }

    render() {

        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 700
        if (maxWidth > 700)
            maxWidth = 700;

        
        return (
            <Popover open={this.props.isOpen}
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}>
            
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold', marginBottom: 20}}>Select a Resource</Typography>  


                    <RadioGroup name="type" value={this.state.selectedResourceId} onChange={this._handleRadioChange} style={{marginTop: 20, marginBottom: 20}}>
                        {this.props.resources.map((resource, index) => 
                            <div key={index} style={{display: 'flex', justifyContent: 'left', gap: 20, alignItems: 'center'}}>
                                <FormControlLabel value={resource.id} control={<Radio color='primary'/>} label={resource.name} style={{minWidth: 130}}/>
                                <Typography variant="body2" style={{color: 'gray', fontStyle: 'italic'}}>{resource.description}</Typography>
                            </div>
                        )}
                    </RadioGroup>
                       
                    <Container style={this.styles.container}>    
                        <Button onClick={this._ok} color="primary" variant="outlined" style={this.styles.button}>Select</Button>
                        <Button onClick={this._cancel} variant="outlined" style={this.styles.button}>Cancel Booking</Button>
                    </Container>
                </Box>

            </Popover>
        );
    }
}