import React, { Component } from 'react';
import { Container, Popover, Button, TextField, Box, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Global } from '../models/Global'

export class DatabaseSelectPopover extends Component {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
        }
    };

 
    _okCallback;
    _cancelCallback;

    state = {
        database: null,
        databaseValue: null
    }
    
    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._cancelCallback = props.cancelCallback;
    }
   
    
    
    _okButtonPressed = () => {
        this._okCallback(this.state.database);
    }

    
    _cancelButtonPressed = () => {
        this._cancelCallback();
    }
    
    
    render() {
        
        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 700
        if (maxWidth > 700)
            maxWidth = 700;
        
        
        return (
            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
                
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Select Database</Typography>  
                    <div style={{marginTop: 20}}/>

                    <Autocomplete
                        size='small'
                        value={this.state.database}
                        onChange={(event, newValue) => { this.setState({database: newValue}); }}
                        inputValue={this.state.databaseValue}
                        onInputChange={(event, newValue) => { this.setState({databaseValue: newValue}); }}
                        options={Global.user.databases}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label="Database" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    /> 

                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} disabled={!this.state.database} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }

}