import React, { Component } from 'react';
import { Typography, Box, Container, Popover, RadioGroup, FormControlLabel, Radio, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Resource } from '../models/Resource';
import { Service } from '../models/Service';

/**
 * This component is a popover that allows the user to select a resource and slot type for a new slot.
 * 
 * The user can supply the following props:
 * 
 * isOpen (boolean): True if the popover is open, false otherwise
 * resources (array of Resource): The resources to choose from
 * selectedResource (Resource): The resource that is selected (optional, default is the first resource)
 * cancelCallback (function): A callback function that is called when the user cancels
 * okCallback (function): A callback function that is called when the user clicks OK, with the selected resource name and slot type as parameters
 *          The slot type is one of 'Booking', 'Block', 'Event'
 */
export class NewSlotSelectionPopover extends Component {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
        }
    };

    state = {
        selectedResource: null,
        slotTypeIndex: -1,
    }
 
    _handleRadioChange = (event) => {
        this.setState({ slotTypeIndex: parseInt(event.target.value) });
    }


    static Type = {
        AVAILABILITY:     {index: 0, name: 'Availability', description: 'Expanded availability for a Service, outside normal hours'},
        BLOCKED_RESOURCE: {index: 1, name: 'Block', description: 'Time that the Resource is unavailable for scheduling'},
        BLOCKED_BOOKING:  {index: 2, name: 'Block', description: 'Time that the Service is unavailable for booking'},
        BOOKING:          {index: 3, name: 'Booking', description: 'A manually created Booking for a patron'},
        EVENT:            {index: 4, name: 'Event', description: 'An scheduled Event on a resource'}
    }


    _availableSlotTypes = (selectedResource) => {
  
        if (selectedResource instanceof Service)
            return [NewSlotSelectionPopover.Type.BOOKING, NewSlotSelectionPopover.Type.BLOCKED_BOOKING, NewSlotSelectionPopover.Type.AVAILABILITY];
        else if (selectedResource instanceof Resource)
            return [NewSlotSelectionPopover.Type.EVENT, NewSlotSelectionPopover.Type.BLOCKED_RESOURCE];
        else {
            return [];
        }
    }

    // Use the selected resource from the state, if it exists, otherwise use the selected resource from the props, otherwise use the first resource
    _selectedResource = () => {
        const selected = this.state.selectedResource;
        if (selected && this.props.resources.includes(selected))    // we have a selected resource and our list of resources contains it
            return selected;

        if (this.props.selectedResource)            // we have one from the props
            return this.props.selectedResource;

        return this.props.resources[0];     // default to the first resource
    }

    // If the selected slot type is not in the available slot types, set it to the first available slot type
    _selectedSlotType = () => {
        const availableSlotTypes = this._availableSlotTypes(this._selectedResource());
        for (const slotType of availableSlotTypes) {
            if (slotType.index === this.state.slotTypeIndex)
                return slotType;
        }
        return availableSlotTypes[0];
    }

    _cancel = () => {
        this.props.cancelCallback();
    }

    _ok = () => {
        this.props.okCallback(this._selectedResource(), this._selectedSlotType().name);
    }

    render() {

        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 700
        if (maxWidth > 700)
            maxWidth = 700;

        const selectedResource = this._selectedResource();
        const availableSlotTypes = this._availableSlotTypes(selectedResource);
        const selectedSlotType = this._selectedSlotType();
        
        return (
            <Popover open={this.props.isOpen}
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}>
            
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold', marginBottom: 20}}>Add to Calendar</Typography>  

                    <Autocomplete
                        id="resource"
                        options={this.props.resources}
                        getOptionLabel={(option) => option.name}
                        value={selectedResource}    
                        onChange={(event, value) => { this.setState({selectedResource: value}); }}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label="Resource or Service" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />

                    {selectedSlotType ? 
                        <RadioGroup name="type" value={selectedSlotType.index} onChange={this._handleRadioChange} style={{marginTop: 20, marginBottom: 20}}>
                            {availableSlotTypes.map((slotType, index) => 
                                <div key={index} style={{display: 'flex', justifyContent: 'left', gap: 20, alignItems: 'center'}}>
                                    <FormControlLabel value={slotType.index} control={<Radio color='primary'/>} label={slotType.name} style={{minWidth: 130}}/>
                                    <Typography variant="body2" style={{color: 'gray', fontStyle: 'italic'}}>{slotType.description}</Typography>
                                </div>
                            )}
                        </RadioGroup>
                        : null
                    }
                     
                    <Container style={this.styles.container}>    
                        <Button onClick={this._ok} color="primary" variant="outlined" style={this.styles.button}>Create</Button>
                        <Button onClick={this._cancel} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>

            </Popover>
        );
    }
}