import { Slot } from './Slot';
import { ThemeColors } from '../Theme';

import { Global } from './Global'
import { Permissions } from 'react-frontend-utils'

export class CalEvent extends Slot {

    static Visibility = {
        PUBLIC: {enumName: "PUBLIC", displayName: "Public", description: "Visible to the public"},
        MEMBERS: {enumName: "LINK", displayName: "Private Link", description: "Visible only to those with a private link"},
        PRIVATE: {enumName: "PRIVATE", displayName: "Internal", description: "Internal use only, hidden for Patrons"}
    };

    owner;
    ownerName;
    visibility;

    constructor(json, timezone) {
        super(json, timezone);
        if (json) {
            this.owner = json.owner;
            this.ownerName = json.ownerName;
            this.visibility = json.visibility;
        }
    }

    static createNew(start, end, resource) {
        
        const event = new CalEvent();
        event.type = "Event";
        event.title = "Untitled Event";
        event.owner = Global.user.id;       // the user creating the event is the owner
        event.ownerName = Global.user.name();
        event.visibility = CalEvent.Visibility.PUBLIC.enumName;
        
        // call superclass method        
        event.newSlot("ev", start, end, [resource.id]);

        event.color = resource.color ? resource.color : ThemeColors.appBarBackground;
        return event;
    }

    copy() {
        const copy = new CalEvent();
        super.copy(copy);
        copy.owner = this.owner;
        copy.ownerName = this.ownerName;
        return copy;
    }

    canEdit(resources) {
        
        if (Global.user.hasPermissionTo(Permissions.MANAGE_RESOURCES))      // resource managers can always edit Events
            return true;

        // Event can be edited if the user has permission to manage events and is the owner
        if (Global.user.hasPermissionTo(Permissions.MANAGE_EVENTS) && this.owner === Global.user.id)
            return true;        
    }



}