import { DateUtils, Permissions } from 'react-frontend-utils'
import store from 'store'

export const DEFAULT_TIMEZONE = "America/New_York";

/**
 * Holds the global variables for the frontend
 */
export const Global = {
    
    user: null,               //object of type User, the currently logged in user or null if none
    
    isResourceManager: () => {
        if (!Global.user)
            return false;

        return Global.user.hasPermissionTo(Permissions.MANAGE_RESOURCES); 
    },


    getDateFormat: () => {
        const fmt = store.get("dateFormat");
        if (!fmt)
            return DateUtils.DateFormatType.ISO8601;
        else
            return fmt;    
    },
    
    setDateFormat: (format) => {
        if (!DateUtils.DateFormatType.isOneOf(format)) {
            console.log("Invalid DateFormatType: " + format);
            return;
        }
        store.set("dateFormat", format);    
    },
    
    dateFormat: (date) => {
        return DateUtils.dateFormat(date, Global.getDateFormat());
    },
    
    checkInTimeFormat: (date) => {
        return DateUtils.timeFormat(date, Global.getDateFormat());
    },

    getLastDatabase: () => {
        return store.get("database");
    },
    
    setLastDatabase: (database) => {
        store.set("database", database);    
    },

    getTimezone: () => {
        return store.get("timezone");
    },

    setTimezone: (timezone) => {
        store.set("timezone", timezone);
    },
    
    getShowBackgroundSlots: () => {
        return store.get("showBackgroundSlots", true);
    },

    setShowBackgroundSlots: (show) => {
        store.set("showBackgroundSlots", show);
    },

    getShowOnlyMySlots: () => {
        return store.get("showOnlyMySlots", false);
    },

    setShowOnlyMySlots: (show) => {
        store.set("showOnlyMySlots", show);
    },

    getHideCancelledSlots: () => {
        return store.get("hideCancelledSlots", false);
    },

    setHideCancelledSlots: (hide) => {
        store.set("hideCancelledSlots", hide);
    },

    getGridSlotDuration: () => {
        return store.get("gridSlotDuration", 30);
    },

    setGridSlotDuration: (duration) => {
        store.set("gridSlotDuration", duration);
    },  
   
    
};
