import { createTheme } from '@material-ui/core/styles'


export const ThemeColors = {
    
    appBarBackground: '#3F51B5',  //primary color for Material-UI
    selectedColor: '#AAAAFF',
    selectedColorHover: '#8888FF',

    listSelectedColor: '#d7d7d755',   //light gray, semi-transparent
    listHeaderColor: '#e0e0e0',     //light gray

    blockColor: '#bcccdb',  //light blue-gray
    availabilityColor: '#ffffff',

    calendarDateButton: '#9cd6a5',
    calendarDateButtonSelected: '#157523',
    
    chargeRed: '#a60b0b',
    clientCreditGreen: '#45bf4b',

    profitGreen: '#1fa152',

    todayTan: '#ebe8d2b4',

    disputeOrange: '#de7d1f',

    transactionDetailTan: '#e2dcc0',
    creditPurple: '#b545bf',
    paymentBlue: '#1f99ad',
    payoutOrange: '#ba7715',
    refundYellow: '#b8d900',
    
    onHoldOrange: '#f2a230',
    logicPurple: '#a600ff',
    hiddenGray: '#9e978d',
    webhookCyan: '#19ffff',
    modifiableGreen: '#21704b',
    mergedOrange: '#ffb82b',

    noticeGreen: '#45bf4b',
    warnYellow: '#b8d900',
            
    instructionsBlue: '#e3efff',
    viewBlue: '#70deff',
    settingsOlive: '#767d63',

    helpCyan: '#00b9b4',
    darkRed: '#AB352C',
    lightBlue: '#4FC3E3',
    mediumGray: '#444444',
    darkGray: '#222222',
    veryLightGray: '#f0f0f0',
    lighterBlue: '#e3f1fa',

    payoutRed: '#EC2424',  //darker redish for charts

    balanceBlue: '#0A0AF0',
    patronGreen: '#03a133',
    validGreen: '#9effa3',

    addColor: '#edaa0c',
    keyColor: '#AB8C00',
    calendarColor: '#a39468',
    clockColor: '#91a6c7',
    mailColor: '#dbb286',
    transactionColor: '#a8a632',
    refundPink: '#d17b7b',
    cancelGray: '#737373',
    adjustPurple: '#a670b5',
    moneyGreen: '#157a58',
    tooltipLightGray: '#EEEEEE',
    redeemIndigo: '#785de3',
    userWarnStatus: '#ff8800',
    newUser: '#275e48',
    overdueOrange: '#f2a230',
    settledPurple: '#b545bf',
    settingsGray: 'a2b6ba',
    templateGray: '#F2F2F2',
    templateTab: '#D2D2D2',
    editGreen: '#45bf4b',
    
    templateBackground: '#b0dae8',
    templateBorder: '#a1c7d4',   
    templateMagenta: '#f542f5',

    tableHeaderBackgroundColor: '#EEEEEE',
    tableAlternatingRowsGray: '#FAFAFA',
    tableHover: '#EEEEFF',
    
    lightTooltipHover: '#FFFFFF1A',
    notifyBackground: '#E6E4D5',
    containerColor: '#FAFAFA',
    applicantTan: '#F2EFD5',
    applicantDrag: '#F5EDA9',
    memberDragOver: '#8C97CF',
    
    errorRed: '#EC2424',
    processingYellow: '#ffeeb5',
    
    veryLightBlue: '#FCFCFF',
        
    signatureStrokeColor: '#0737b0',
    signaturePadColor: '#ebe9d3',

    barChartGrey: '#375168',
    
    pieChartColors: ['#6fb2e0', '#e03434', '#42db42', '#f7cd40', '#9449f5', '#f035e3', '#3febe8', '#eb873f', '#4c804b', '#ff9c9c', '#826c38', '#386782',
                     '#85283d', '#9ca635', '#8c4d1d', '#cbb8ff', '#b1d190', '#c9c08f', '#363230'],

    resourceColors: [
        "#a9cae8",  // Lightened Muted Blue
        "#b1daa8",  // Lightened Muted Green
        "#e4e769",  // Lightened Muted Yellow
        "#ef9696",  // Lightened Muted Pink
        "#b496dd",  // Lightened Muted Purple
        "#efb579",  // Lightened Muted Orange
        "#97e2ec",   // Lightened Muted Cyan
        "#d24bd3",   // Lightened Muted Magenta
    ]
    
    
    
};

const AGTheme = createTheme({
    
    palette: {
        light: '#4444FF'
    },
    styledTooltip: {
        backgroundColor: '#000099',
        color: 'white',
        maxWidth: 500,
        fontSize: 12,
        border: '2px solid #ffffff'
    },
    overrides: {
        MuiContainer: {
            root: {
              padding: '10px'
            }
        },
        MuiListItem: {
            root: {
              "&$selected": {backgroundColor: ThemeColors.selectedColor, "&:hover": {backgroundColor: ThemeColors.selectedColorHover}}
            }
        }
    }
    
});


export default AGTheme


