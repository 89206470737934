import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { AppBar, Toolbar, Typography, Button, IconButton, Container } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import TodayIcon from '@material-ui/icons/Today';
import WorkIcon from '@material-ui/icons/Work';
import PeopleIcon from '@material-ui/icons/People';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { Global } from '../models/Global'
import AGTheme, { ThemeColors } from '../Theme'
import { RestComponent, HomepageContext, PopupMenu, StyledTooltip } from 'react-frontend-utils'
import { User } from 'react-frontend-utils'
import { DatabaseSelectPopover } from '../components/DatabaseSelectPopover';
import { WelcomePage } from '../pages/WelcomePage'
import ExpiredPage from '../pages/ExpiredPage'
import CalendarPage from '../pages/CalendarPage'
import ServicesPage from '../pages/ServicesPage'
import { logo } from '../App'


//This is the main page for the Web Portal. It contains the AppBar and MainMenu and handles login/logout.
//Below the AppBar, the HomePage selects one Page out of the available Pages to display, based on what
//is selected by the user. The WecomePage is the initial page shown before login.


//These are the available Pages that can be selected from the MainMenu that will be displayed below the AppBar
export const Pages = {
    EMPTY:  "EMPTY",
    WELCOME: "WELCOME",
    EXPIRED: "EXPIRED",
    CALENDARS: "CALENDARS",
    SERVICES: "SERVICES",
    
    //Returns true if the type is one of the MainMenuSwitch types, false otherwise
    isOneOf: (type) => {
        switch (type) {
            case Pages.EMPTY:
            case Pages.WELCOME:
            case Pages.EXPIRED:
            case Pages.CALENDARS:
            case Pages.SERVICES:
                return true;
            default:
                return false;
        }
    }
};
Object.freeze(Pages);



const AppBarIconButton = withStyles((theme) => ({
    root: {
        '&:hover': {backgroundColor: ThemeColors.lightTooltipHover}
    }
}))(IconButton);

const AppBarButton = withStyles((theme) => ({
    root: {
        '&:hover': {backgroundColor: ThemeColors.lightTooltipHover}
    }
}))(Button);


const poolPassIcon = (<PeopleIcon fontSize="small"/>);
const helpIcon = (<HelpOutlineIcon fontSize="small"/>);


const gotoPoolPassPortal = () => {
    window.location.href ="https://portal.pool-pass.com";
};

const gotoHelp= () => {
    window.location.href = "https://support.accessgrantedsystems.com/portal";        
};


class Home extends RestComponent {
  
  
    styles = {
        appTitle: {
           marginLeft: 10,
           textShadow: "2px 2px #333333",
           fontWeight: "bold",
           fontSize: "200%",
           flexGrow: 1   //fill all space to push other elements to the right edge
        }
    }
 
    _databaseChangedEvent;


    constructor(props) {
        super(props);
        const database = props.database ? decodeURIComponent(props.database) : null;  //Arrives with the query string set to the desired database
        if (database)
            Global.setLastDatabase(database);

        this.state.isAuthenticated = false;       //true if the current user has been authenticated via OAuth
        this.state.serverError = null;
        this.state.isMobile = false;
        this.state.currentUser = null;
        this.state.selectedPage = Pages.WELCOME;  //default
        this.state.databasePopoverOpen = false;
        this.state.manageServiceId = null;

        this.state.bookingPortalEnabled = false;
        this._databaseChangedEvent = new Event('databaseChangeEvent');
    }


    /**
     * When the Home page loads, see if the user is currently authenticated
     */
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        this._fetchCurrentUser();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isMobile: window.innerWidth < 600 });  //custom, split between bootstrap and mui
    }


    _fetchCurrentUser = () => {
        this.secureJSONFetch("/ppcs/currentUser", {}, this._checkUserCallback); 
    }


    /**
     * Callback to be executed from fetching the current user
     * @param {Object} response null if there is no current authenticated user, or a JSON object describing the user
     */
    _checkUserCallback = (response) => {
        if (response === null) {
            this.setState(({isAuthenticated: false, currentUser: null}));  //user is not authenticated
            Global.user = null;
            console.log("No user is currently logged in");
     
        } 
        else {
            const user = new User(response);  //create the global user
            this.setState({currentUser: user, isAuthenticated: true});
            
            console.log("User: " + user.name()  + " (" + user.id + "/" + user.role + ") logged in, available databases are: " + user.databases.toString());
            console.log(user.name() + " has these permissions: " + JSON.stringify(user.getPermissions()));

            Global.user = user;

            let lastDatabase = Global.getLastDatabase();
            if (!lastDatabase || !user.databases.includes(lastDatabase)) {  // if no last database, or user doesn't have last database, use their first
                lastDatabase = Global.user.databases[0];  //use first
                console.log("No last database set, last database unavailable, now using " + lastDatabase);
                Global.setLastDatabase(lastDatabase);
            }
            this._changeDatabase(lastDatabase, true);      
        }
    }

   
    /**
     * User Action by pressing the LOGIN button
     * Attempt to login by accessing a protected resource - browser is redirected to OAuth login page
     */
    _login = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ":3000") {
          port = ":8080";  //switch to 8080 if using yarn development port
        }
        window.location.href = "//" + window.location.hostname + port + "/ppcs/private"; 
    }


        
    /**
     * User action by pressing the LOGOUT button
     * Logout - response indicates a link to follow after logout
     */
    _logout = () => {
        
        this.secureJSONFetch("/ppcs/logout", {method: "POST"}, (result) => {
            window.location.href = result.logoutUrl + "?id_token_hint=" + result.idToken + "&post_logout_redirect_uri=" + window.location.origin;
            this.setState({currentUser: null, isAuthenticated: false, manageServiceId: null});
            Global.user = null;
        },
        (error) => {
            this.setState({serverError: error.toString()});
            Global.user = null;
        });  
    }
    
    sessionExpired = () => {
        this.setState({selectedPage: Pages.EXPIRED, currentUser: null, isAuthenticated: false});
    }

    
    /**
     * Callback from the MainMenu component when a user selects an item from the menu 
     * @param {Pages item} selectedItem one of the Pages items
     */
    mainMenuCallback = (selectedItem) => {
        
        if (Pages.isOneOf(selectedItem)) {
            this.setState({selectedPage: selectedItem}); 
            console.log("Switching page to " + selectedItem);
        }
        else {
            console.log("Invalid Page passed to mainMenuCallback");
        }
    }

    _gotoCalendarsPage = () => {
        this.setState({selectedPage: Pages.CALENDARS});
    }

    _gotoServicesPage = (id) => {
        this.setState({selectedPage: Pages.SERVICES, manageServiceId: id});
    }
    
    _mainMenu = () => {
  
    
        const items = (() => {

            let menuItems = [];

            if (this.state.bookingPortalEnabled) {
                menuItems.push({label: "Calendars", 
                                icon: <TodayIcon fontSize="small"/>, 
                                isSelected: (this.state.selectedPage === Pages.CALENDARS), 
                                selectCallback: this._gotoCalendarsPage
                                });
                        
                menuItems.push({label: "Services", 
                    icon: <WorkIcon fontSize="small"/>, 
                    isSelected: (this.state.selectedPage === Pages.SERVICES), 
                    selectCallback: this._gotoServicesPage
                    });

                menuItems.push(null);
            }

    
            menuItems.push({label: "Return to Membership Portal", 
                                icon: poolPassIcon, 
                                isSelected: false, 
                                selectCallback: gotoPoolPassPortal
                            });


            menuItems.push({label: "Help", 
                            icon: helpIcon, 
                            isSelected: false, 
                            selectCallback: gotoHelp}
                            );

            return menuItems;
        })();

        return <PopupMenu menuIcon={<MenuIcon/>} menuItems={items} lighterHover={true}/>;
    }


    _showHealth = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ":3000") {
            port = ":8080";  //switch to 8080 if using yarn development port
        }
        window.location.href = "//" + window.location.hostname + port + "/monitoring"; 
    }
    
    _showLogs = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ":3000") {
            port = ":8080";  //switch to 8080 if using yarn development port
        }
        window.location.href = "//" + window.location.hostname + port + "/actuator/logfile"; 
    }

    
    _changeDatabase = (database) => {
        this.setState({databasePopoverOpen: false});

        console.log("New database is " + database);

        Global.setLastDatabase(database);

        this.incrementBusy();

        //Fetch database - is it enabled for booking?
        this.secureJSONFetch("/bk/" + database + "/enabled", {}, this._fetchDBEnabledCallback, this._fetchDBFailCallback); 
    }

    // success - we are ok to proceed with booking on this database
    _fetchDBEnabledCallback = (response) => {
        this.incrementBusy();
        this.setState({bookingPortalEnabled: true});

        // Fetch the timezone for the current database
        this.incrementBusy();
        this.secureJSONFetch("/tz/" + Global.getLastDatabase(), {}, this._fetchTimezoneCallback, this._fetchErrorCallback);
    }

    // When the timezone is fetched, update the timezone state, if none is set, show an alert, and set the default timezone to America/New_York
    _fetchTimezoneCallback = (response) => {
        this.decrementBusy();
        if (response) {
            if (response.group !== Global.getLastDatabase()) {
                this.showConfirmAlert("Error", "Timezone response for wrong database", 'red');
                return;
            }
            if (!response.timezone) {
                this.showConfirmAlert("No Timezone Set", "Please set your community's timezone. Until changed, the default timezone will be America/New_York", 'red');
                Global.setTimezone(Global.DEFAULT_TIMEZONE);
            }
            else
                Global.setTimezone(response.timezone);

            this.setState({selectedPage: Pages.CALENDARS});
            dispatchEvent(this._databaseChangedEvent);   //let everyone know the database has changed!         }   
        }
    }

    // fail - database not found, or not authorized yet
    _fetchDBFailCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
        this.setState({selectedPage: Pages.EMPTY, bookingPortalEnabled: false});    
    }


    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }


    render() {

        //Set the login button based on the state of the authentication
        const loginLogoutButton = this.state.isAuthenticated ?
            <AppBarButton color="inherit" onClick={() => { this._logout(); }}>LOGOUT</AppBarButton>
            :
            <AppBarButton color="inherit" onClick={() => { this._login(); }}>LOGIN</AppBarButton>;


        //Only shows the main menu when authenticated 
        const showMainMenu = this.state.isAuthenticated;

           
        //Selects the current page to view
        const viewingPage = (() => {
                       
            switch (this.state.selectedPage) {

                case Pages.EMPTY:
                    return <div/>

                case Pages.WELCOME:   
                    return <WelcomePage onLogin={this._login}/>;
              
                case Pages.EXPIRED:   
                    return <ExpiredPage/>;
                    
                case Pages.CALENDARS:
                    return <CalendarPage manageServiceCallback={this._gotoServicesPage}/>;

                case Pages.SERVICES:
                    return <ServicesPage manageServiceId={this.state.manageServiceId}/>;

                default:
                    return <div>Page Not Found</div>;

            }
        })();
        
        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;
      
        const gutterMargin = this.state.isMobile ? 8 : 20;
        
        return (
            <HomepageContext.Provider value={{sessionExpiredCallback: this.sessionExpired}}>
    
                <ThemeProvider theme={AGTheme}>
                    <Fragment>

                        {this.getConfirmAlertComponent()  /*inject an alert component*/}  

                        {Global.user ? 
                            <DatabaseSelectPopover isOpen={this.state.databasePopoverOpen} okCallback={(database) => this._changeDatabase(database)} 
                                                  cancelCallback={() => this.setState({databasePopoverOpen: false})}/>   
                                : null
                        }
                        
                        <AppBar position="static" style={{marginBottom: 12, backgroundColor: ThemeColors.appBarBackground}}>
                            <div style={{paddingTop: 0, paddingBottom: 4, paddingLeft: gutterMargin, paddingRight: gutterMargin}}>

                                <Toolbar disableGutters={true}>
                                     {logo}
                                     <Typography variant="h5" style={this.styles.appTitle}>Booking Portal</Typography>

                                     {Global.user && Global.user.databases.length > 1 ? 
                                        <StyledTooltip title="Switch Database">
                                            <AppBarButton color="inherit" style={{marginRight: 30}} onClick={() => this.setState({databasePopoverOpen: true})}>⇋ DATABASE</AppBarButton>
                                        </StyledTooltip>
                                        : null
                                     }

                                     {this.state.currentUser && this.state.currentUser.isSuperAdmin(true) ? 
                                        <div>
                                            <AppBarIconButton edge="end" onClick={this._showLogs} style={{marginRight: 5}} >
                                                <DescriptionOutlinedIcon fontSize="small" style={{color: 'white'}}/>
                                            </AppBarIconButton>
                                            <AppBarIconButton edge="end" onClick={this._showHealth} style={{marginRight: 5}} >
                                                <FavoriteBorderIcon fontSize="small" style={{color: 'white'}}/>
                                            </AppBarIconButton>
                                        </div>
                                        : null
                                     }
                                     
                                      
                                     {loginLogoutButton}
                                     <div style={{padding: 5}}/>
                                     {showMainMenu ? this._mainMenu() : null}


                                </Toolbar>
                                <div style={{display: "flex", flexDirection: 'column'}}>
                                    <Typography variant="subtitle1" style={{textAlign: 'left', fontSize: this.state.isMobile ? 13 : 16}}>{this.state.currentUser ? ("User: " + this.state.currentUser.name()) : null}</Typography>
                                    <Typography variant="subtitle1" style={{textAlign: 'left', fontSize: this.state.isMobile ? 13 : 16}}>{this.state.currentUser ? ("Database: " + Global.getLastDatabase()) : null}</Typography>
                                </div>
                            </div>
                        </AppBar>


                        {serverErrorMessage}
                        
                        <Container maxWidth={false} disableGutters={true} style={{paddingLeft: gutterMargin, paddingRight: gutterMargin}}>
                            {viewingPage} 
                        </Container>

                    </Fragment>
                </ThemeProvider>    
            </HomepageContext.Provider>
        );
    }
};

export default withCookies(withRouter(Home));


