import React, { useState } from 'react';  
import { Typography, IconButton, Box, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';


export function CategoryGroup(props) {

    const [open, setOpen] = useState(true);

    const handleToggle = () => {
        setOpen(!open);
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: -5}}>
                <Typography variant="body2" style={{marginLeft: 5}}>{props.categoryName ? props.categoryName : "Uncategorized"}</Typography>
                <IconButton size="small" onClick={handleToggle}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </div>
            <Collapse in={open}>
                <Box style={{ border: '1px solid lightGray', borderRadius: 5, paddingTop: 7, paddingBottom: 7}}>
                    {props.children}
                </Box>
            </Collapse>
        </div>
    );
}