import { Slot } from './Slot';
import { ThemeColors } from '../Theme';

import { Service } from './Service';
import { Global } from './Global'
import { Permissions } from 'react-frontend-utils'

export class Booking extends Slot {

    patronCount;
    patronNames;
    patronEmail;
    optionalCosts;
    calculatedCost;

    patronBooked;
    paymentIntent;
    paidOn;
    refundedAmount;
        
    constructor(json, timezone) {
        super(json, timezone);
        if (json) {
            this.patronCount = json.patronCount;
            this.patronNames = json.patronNames;
            this.patronEmail = json.patronEmail;
            this.optionalCosts = json.optionalCosts;
            this.calculatedCost = json.calculatedCost;
            this.patronBooked = json.patronBooked;
            this.paymentIntent = json.paymentIntent;
            this.paidOn = json.paidOn;
            this.refundedAmount = json.refundedAmount;
        }
    }

    static createNew(start, end, resource) {
        
        const booking = new Booking();
        booking.type = "Booking";
        booking.title = "Untitled Booking";
        
        // call superclass method        
        booking.newSlot("bk", start, end, [resource.id]);

        booking.color = resource.color ? resource.color : ThemeColors.appBarBackground;
        return booking;
    }

    copy() {
        const copy = new Booking();
        super.copy(copy);
        copy.patronCount = this.patronCount;
        copy.patronNames = this.patronNames;
        copy.patronEmail = this.patronEmail;
        copy.optionalCosts = this.optionalCosts;
        copy.calculatedCost = this.calculatedCost;
        copy.patronBooked = this.patronBooked;
        copy.paymentIntent = this.paymentIntent;
        copy.paidOn = this.paidOn;
        copy.refundedAmount = this.refundedAmount;
        return copy;
    }

    removeFieldsForNewSlot() {
        super.removeFieldsForNewSlot();
        this.patronCount = null;
        this.patronNames = null;
        this.patronEmail = null;
        this.optionalCosts = null;
        this.calculatedCost = null;
        this.patronBooked = null;
        this.paymentIntent = null;
        this.paidOn = null;
        this.refundedAmount = null;
    }

    canEdit(resources) {
        if (Global.user.hasPermissionTo(Permissions.MANAGE_RESOURCES))      // resource managers can always edit Bookings
            return true;
        
        const service = this.getService(resources);
        if (!service) {
            console.error("Booking " + this.id + " does not have a Service, which is not allowed");
            return false;
        }

        if (service.owner === Global.user.id) {        // owners of services can always edit their bookings (and there can only be one)
            return true;
        }
        
        return false;
    }

    // Return the Service that this booking is on, or null if it is not on a service. We assume that a booking can only be on one service.
    getService = (resourcesList) => {

        // step through all our resourceIDs, and find the resource in the list that matches it. If it is a service, it's ours
        for (let resourceId of this.resourceIds) {
            const resource = resourcesList.find(r => r.id === resourceId)
            if (resource instanceof Service)
                return resource;
        }
        return null;
    }

    // Removes all assigned resources from the booking except the service
    removeAllAssignedResources = (resourcesList) => {
        const service = this.getService(resourcesList);
        if (service)
            this.resourceIds = [service.id];    // just keep the service
        else
            this.resourceIds = [];
    }


    status() {
        if (this.state === "CREATED")
            return "Awaiting Confirmation";
        else if (this.state === "ACTIVE") {
            if (!this.patronBooked)
                return "Manually Confirmed";
            if (this.calculatedCost === 0)
                return "Confirmed (Free)";
            else if (this.paymentIntent && this.paidOn)
                return "Confirmed (Paid)";
            else
                return "Payment Pending";
        }
        else 
            return super.status();
    }

    details() {

        // Get a comma seperated list of Patrons
        let names = "";
        if (this.patronNames)
            names = this.patronNames.join(", ");
        
        // Additional purchases:
        let additional = "";
        if (this.optionalCosts)
            additional = this.optionalCosts.join(", ");


        return (
            "<div>" +
                (this.patronEmail ? ("<div>Contact Email: " + this.patronEmail + "</div>") : "") +
                (this.patronCount > 0 ? ("<div>Patron" + (this.patronCount > 1 ? "s" : "") + ": " + names + "</div>") : "") +
                (additional ? ("<div>Additional Purchases: " + additional + "</div>") : "") +
            "</div>"
        );
    }

    symbol() {
        if (this.state === "ACTIVE" && this.calculatedCost > 0 && this.paymentIntent && !this.paidOn)
                return "💵";        // awaiting payment
        
        return super.symbol();
    }


}