import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import ReplyIcon from '@material-ui/icons/Reply';
import { Tooltip, Grid, Typography, Checkbox, Paper, TextField, Button, IconButton } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ThemeColors } from '../Theme'
import { Global } from '../models/Global'
import { Service, ServiceParams } from '../models/Service'
import { CalendarPage } from './CalendarPage';
import { LuxonDatePicker } from '../components/LuxonDateTimePicker'

import { RestComponent } from 'react-frontend-utils' 
import { OpenInNewTab } from '../App'

import { DateUtils, SummaryWidget, ManageTextField, ManageDecimalField, ManageNumericField, ManageTimeField, RichTextEditor } from 'react-frontend-utils'
import { DateConversions } from '../utils/DateConversions';

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const longDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export class ServicesPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 5,
            marginBottom: 30,
            color: 'gray',
            fontSize: '14pt',
            flexGrow: 1
        },
        paper: {
            padding: 10
        },
        roundedContainer: {
            border: '1px solid #CCCCCC', 
            borderRadius: '4px', 
            padding: 10,
            marginBottom: 20,
        },
        containerLabel: {
            fontSize: 14, 
            color: ThemeColors.darkGray, 
            marginLeft: 5
        },
        sectionLabel: {
            fontSize: 16, 
            color: 'black', 
            marginLeft: 5
        },
        viewLabel: {
            fontSize: 12, 
            color: 'gray', 
            marginLeft: 5
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 100,
            float: 'right',
            textTransform: 'uppercase',
            padding: 5, 
            marginRight: 10,
            marginTop: 2,
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
    };

    _manageServiceId = null;        // from the Calendar page
    _currentDatabase = Global.getLastDatabase();

    constructor(props) {
        super(props);
        
        this.state.services = [];                 //The list of Services
        this.state.selectedService = null;        //The currently selected Service
        this.state.status = null;                  //The status of the selected Service

        this.state.seasonBookingAutoEnableChecked = false;
        this.state.seasonBookingStartChecked = false;
        this.state.seasonBookingEndChecked = false;

        this.state.usesSlotCalendar = false;
    }
    
    
   
    componentDidMount() {
        super.componentDidMount();
        window.addEventListener("databaseChangeEvent", this._databaseChanged);
        this._manageServiceId = this.props.manageServiceId;
        this._fetchAll();
    }

    // When the database changes, refetch the Services
    _databaseChanged = () => {
        if (Global.getLastDatabase() !== this._currentDatabase) {
            console.log("Database changed to " + Global.getLastDatabase() + ", refetching Services");
            this._fetchResources();
            this._currentDatabase = Global.getLastDatabase();
        }
    }
    
    //Fetch all Services
    _fetchAll = () => {
        this.setState({services: [], selectedService: null, status: null});
   
        this.incrementBusy();
        this.secureJSONFetch("/bk/" + Global.getLastDatabase() + "/resources", {}, 
                            this._fetchResourcesCallback, this._fetchErrorCallback);         
    }
    
    // When the resources are fetched, filter them into just Services
    _fetchResourcesCallback = (response) => {
        this.decrementBusy();
        if (response) {
            const resources = response.map((json) => CalendarPage.resourcefromJson(json));
            const services = resources.filter((resource) => resource.type === "Service");

            const aggregate = {active: {count: 0, names: []}, waiting: {count: 0, names: []}, finished: {count: 0, names: []}, disabled: {count: 0, names: []}};
            services.forEach((service) => {
                switch(service.status()) {
                    case Service.Status.DISABLED:
                        aggregate.disabled.count++;
                        aggregate.disabled.names.push(service.name);
                        break;
                    case Service.Status.WAITING:
                        aggregate.waiting.count++;
                        aggregate.waiting.names.push(service.name);
                        break;
                    case Service.Status.FINISHED:
                        aggregate.finished.count++;
                        aggregate.finished.names.push(service.name);
                        break;
                    case Service.Status.ACCEPTING:
                        aggregate.active.count++;
                        aggregate.active.names.push(service.name);
                        break;
                    default:
                        console.error("Unknown status for Service: " + service.status());
                        break;
                }
            });

            if (this._manageServiceId) {
                const selectedService = services.find((service) => service.id === this.props.manageServiceId);
                if (selectedService)
                    setTimeout(() => this._selectService(null, selectedService));
            }

            this.setState({services: services, aggregate: aggregate});

        }
    }
   
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
  
    _gotoPatronBooking = () => {
        OpenInNewTab("patron/booking?database=" + this._currentDatabase + "&serviceid=" + this.state.selectedService.id);
    }

    
    _discard = () => {
        this._manageServiceId = null;
        this._fetchAll();
    }


    //Post the current ServiceParams to modify to the server
    _save = () => {
        if (this.state.selectedService) {   
            
            // Check each of the business hours to make sure open is before close
            for (let i = 0; i < 7; i++) {
                if (this.state.selectedService.serviceParams.businessHours[i].enabled) {
                    const diff = DateUtils.diffTimeStrings(this.state.selectedService.serviceParams.businessHours[i].open, this.state.selectedService.serviceParams.businessHours[i].close);
                    if (diff < 0) {
                        this.showConfirmAlert("Error", "On " + longDays[i] + ", the closing time must be after the opening time", 'red');
                        return;
                    }
                }
            }

            //Make a copy of the selected ServiceParams to be posted back to the server.  Date fields are converted from luxon date objects to json date strings
            const serviceParamsToModify = ServiceParams.copyForPosting(this.state.selectedService.serviceParams);

            if (!this.state.seasonBookingAutoEnableChecked)
                serviceParamsToModify.seasonBookingAutoEnable = null;       // null this field if the checkbox is not checked
    
            if (!this.state.seasonBookingStartChecked)
                serviceParamsToModify.seasonBookingStart = null;       // null this field if the checkbox is not checked
    
            if (!this.state.seasonBookingEndChecked)
                serviceParamsToModify.seasonBookingEnd = null;       // null this field if the checkbox is not checked
    
            
        
            this.incrementBusy();
            this.secureJSONFetch("/bk/services/" + this.state.selectedService.id + "/serviceParams", {method: 'POST', body: JSON.stringify(serviceParamsToModify)}, 
                                 this._saveCallback, this._fetchErrorCallback);
        }
    }
    
    
    _saveCallback = () => {
        this.showConfirmAlert("Success", "Service \"" + this.state.selectedService.name + "\" updated", 'green'); 
        this.decrementBusy();
        this._fetchAll();
    }
  
 
    
    //Callback when a Service is selected from the available list, display the selected Service
    _selectService = (event, newValue) => { 

        console.log("Selected Service: " + newValue.name);
        const selectedService = newValue;

        //We need to hold the status in the state, since we're going to modify the selectedService object below which will affect status
        const status = selectedService.status();

        this.setState({selectedService: selectedService, status: status,
                       seasonBookingAutoEnableChecked: selectedService.serviceParams.seasonBookingAutoEnable ? true : false,
                       seasonBookingStartChecked: selectedService.serviceParams.seasonBookingStart ? true : false,
                       seasonBookingEndChecked: selectedService.serviceParams.seasonBookingEnd ? true : false});

        // If these fields aren't set, LuxonDatePicker is going to initialize them to the current date. So if the user never changes them, we want our value
        // to be set to the current date as well.  If the check mark is never checked, we don't care, since we'll null them out before post
        const now = DateConversions.now(Global.getTimezone());
        if (!selectedService.serviceParams.seasonBookingAutoEnable)
            selectedService.serviceParams.seasonBookingAutoEnable = now;
        if (!selectedService.serviceParams.seasonBookingStart)
            selectedService.serviceParams.seasonBookingStart = now;
        if (!selectedService.serviceParams.seasonBookingEnd)
            selectedService.serviceParams.seasonBookingEnd = now;

        // Set some default hours of operation if not set
        for (let i = 0; i < 7; i++) {
            if (!selectedService.serviceParams.businessHours[i])
                selectedService.serviceParams.businessHours[i] = {enabled: false, allDay: false};

            if (!selectedService.serviceParams.businessHours[i].open)
                selectedService.serviceParams.businessHours[i].open = DateUtils.parseTimeString("9:00 AM");
            if (!selectedService.serviceParams.businessHours[i].close)
                selectedService.serviceParams.businessHours[i].close = DateUtils.parseTimeString("5:00 PM");
        }

        this.setState({usesSlotCalendar: selectedService.usesSlotCalendar()});
    }
    

    
    //A field in the Service was changed, update the selected Service object with the new data
    _serviceParamsFieldChanged = (fieldName, userValue) => {
        this.state.selectedService.serviceParams[fieldName] = userValue;  
        this.setState({usesSlotCalendar: this.state.selectedService.usesSlotCalendar()});
    }

    _onDateParseError = (title, message) => {
        this.showConfirmAlert(title, message, 'red');
    }
    
    _tooltipForCount = (nameList, title) => {
        
        const spliceList = [...nameList];  //shallow copy
        const matrix = [];
        while (spliceList.length) 
            matrix.push(spliceList.splice(0,3));
        
        return  <div>
                    <Typography variant='caption' style={{borderBottom: '1px solid white'}}>{title}</Typography>
                    <table>
                        <tbody>
                        {matrix.map(row => <tr>
                                                <td style={{fontSize: 13, paddingRight: 10}}>{row[0]}</td>
                                                <td style={{fontSize: 13, paddingRight: 10}}>{row[1]}</td>
                                                <td style={{fontSize: 13}}>{row[2]}</td>
                                           </tr>
                                   )}
                        </tbody>
                    </table>
                </div>;
    }

    _dateBoxChecked = (field, event) => {
        this.state[field + "Checked"] = event.target.checked;
        this.forceUpdate();
    }
    

    _dayHoursOfOperation = (day) => {

        const sp = this.state.selectedService.serviceParams;
        const isUs = DateConversions.isUSDateFormat();
        const initialOpenTimeString = isUs ? DateUtils.toFriendlyTimeString(sp.businessHours[day].open) : sp.businessHours[day].open;
        const initialCloseTimeString = isUs ? DateUtils.toFriendlyTimeString(sp.businessHours[day].close) : sp.businessHours[day].close;

        const onFieldChange = (json, timeStr) => {
            if (json === "open")
                sp.businessHours[day].open = DateUtils.parseTimeString(timeStr);  // convert to 24 hour time, if needed
            else
                sp.businessHours[day].close = DateUtils.parseTimeString(timeStr);  // convert to 24 hour time, if needed
        };

        return (
            <div key={day} style={{display: 'flex', alignItems: 'center', justifyContent: 'left', marginTop: 5, marginBottom: 5, height: 50}}>
                <Checkbox checked={sp.businessHours[day].enabled} color='primary'
                        onChange={(event) => {sp.businessHours[day].enabled = event.target.checked; this.forceUpdate();}}/>  


                <Typography variant='body1' style={{marginRight: 30, width: 90}} align='left'>{days[day]}</Typography>
                {sp.businessHours[day].enabled ? 
                    <Fragment>
                        <Checkbox checked={sp.businessHours[day].allDay} color='primary'
                                  onChange={(event) => {sp.businessHours[day].allDay = event.target.checked; this.forceUpdate();}}/>
                        <Typography variant='body1' style={{marginRight: 30, width: 90}} align='left'>All Day</Typography>

                        {!sp.businessHours[day].allDay ?
                            <Fragment>
                                <ManageTimeField label="Opens" 
                                                style={{maxWidth: 200, marginRight: 20}}
                                                json="open"
                                                autoAccept={true}
                                                clockColor={ThemeColors.clockColor} 
                                                twelveHour={isUs}
                                                initialValue={initialOpenTimeString} 
                                                onFieldChange={onFieldChange} 
                                                onParseError={this._onDateParseError}/> 
                                <ManageTimeField label="Closes" 
                                                style={{maxWidth: 200}}
                                                json="close"
                                                autoAccept={true}
                                                clockColor={ThemeColors.clockColor} 
                                                twelveHour={isUs}
                                                initialValue={initialCloseTimeString} 
                                                onFieldChange={onFieldChange} 
                                                onParseError={this._onDateParseError}/> 
                            </Fragment> 
                            : null
                        }
                    </Fragment>
                    : null
                }
            </div>
        );
    }


    _addAdditionalCostItem = () => {
        this.state.selectedService.serviceParams.additionalCosts.push({name: "", cost: 0});
        this.forceUpdate();
    }

    _additionalCostItem = (item, index) => {

        const onFieldChange = (json, val) => {
            if (json === "name")
                item.name = val;
            else
                item.cost = parseFloat(val);
        };

        const removeItem = () => {
            this.state.selectedService.serviceParams.additionalCosts.splice(index, 1);
            this.forceUpdate();
        }

        return (
            <div key={index} style={{display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 20, marginTop: 10, marginBottom: 5}}>
                
                <ManageTextField label="Cost Name"
                                initialValue={item.name}
                                json="name"  
                                tooltip="Name for the additional cost the Patron may optionally purchase"
                                autoAccept={true}
                                onFieldChange={onFieldChange}/>

                <div style={{minWidth: 300}}>
                    <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                    tooltip={"Optional Cost"}
                                    label={"Optional Cost (" + this.state.selectedService.isoCurrency + ")"} minValue={0}
                                    decimalPlaces={2}
                                    json="cost"
                                    initialValue={item.cost}
                                    onFieldChange={onFieldChange}
                                    changedBackgroundColor='white'/> 
                </div>
                
                <Tooltip title="Remove optional cost item">
                    <IconButton onClick={removeItem} >
                        <ClearOutlinedIcon fontSize="large" style={{color: ThemeColors.darkRed}}/>
                    </IconButton>
                 </Tooltip>
            </div>
        );
    }




    //------------------------------- RENDER ----------------------------------------------
    
    render() {
       
        const buttonStyle = (activeColor, disabled) => {     
            let color = disabled ? 'lightGray' : activeColor;
            return {borderColor: color, color: color, textAlign: 'center'};
        };
       
        const showService = this.state.selectedService !== null;
        const canSave = showService && this.state.selectedService.canManage();
            
        const sp = this.state.selectedService && this.state.selectedService.serviceParams ? this.state.selectedService.serviceParams : null;

        const currency = this.state.selectedService ? this.state.selectedService.isoCurrency : "???";

        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}
                
                <Grid container direction="row" spacing={2} style={{padding: 10, marginBottom: 20}}>
                    <Grid item sm={3} xs={6}>
                        <SummaryWidget label="Accepting" 
                                value={this.state.aggregate ? this.state.aggregate.active.count : "..."}
                                tooltip={this._tooltipForCount(this.state.aggregate ? this.state.aggregate.active.names : [], "Services that are currently accepting Bookings")}
                                tooltipWidth='100%'
                                borderColor={Service.Status.ACCEPTING.color}/>
                    </Grid>                  
                    <Grid item sm={3} xs={6}>
                        <SummaryWidget label="Waiting" 
                                value={this.state.aggregate ? this.state.aggregate.waiting.count : "..."}
                                tooltip={this._tooltipForCount(this.state.aggregate ? this.state.aggregate.waiting.names : [], "Services that have not started accepting Bookings")}
                                tooltipWidth='100%'
                                borderColor={Service.Status.WAITING.color}/>
                    </Grid>         
                    <Grid item sm={3} xs={6}>
                        <SummaryWidget label="Finished" 
                                value={this.state.aggregate ? this.state.aggregate.finished.count : "..."}
                                tooltip={this._tooltipForCount(this.state.aggregate ? this.state.aggregate.finished.names : [], "Services no longer accepting Bookings")}
                                tooltipWidth='100%'
                                borderColor={Service.Status.FINISHED.color}/>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                       <SummaryWidget label="Disabled" 
                                value={this.state.aggregate ? this.state.aggregate.disabled.count : "..."}
                                tooltip={this._tooltipForCount(this.state.aggregate ? this.state.aggregate.disabled.names : [], "Services manually disabled")}
                                tooltipWidth='100%'
                                borderColor={Service.Status.DISABLED.color}/>
                    </Grid>
                </Grid>
                
                
  
                <div style={{display: 'flex', gap: 20, marginLeft: 10, marginRight: 10, justifyContent: 'left', alignItems: 'center'}}>
                    <Autocomplete
                        size='small'
                        disabled={showService}
                        style={{width: '35%'}}
                        value={this.state.selectedService}
                        onChange={this._selectService}
                        options={this.state.services}
                        blurOnSelect
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Service" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />            
                
                    <Tooltip title="Save Changes and Refresh">
                        <Button disabled={!canSave}  size='small' style={buttonStyle('green', !canSave)} 
                                onClick={this._save} variant="outlined" component="label">
                            Save
                        </Button>
                    </Tooltip>                    
                    <Tooltip title="Discard Changes and Clear">
                        <Button disabled={!showService}  size='small' style={buttonStyle('black', !showService)} 
                                onClick={this._discard} variant="outlined" component="label">
                            Discard
                        </Button>
                    </Tooltip>

                    <Button disabled={!showService} size='small' style={{...buttonStyle('black', !showService), marginLeft: 'auto'}} 
                            onClick={this._gotoPatronBooking} variant="outlined" component="label"
                            startIcon={<ReplyIcon style={{transform: 'scaleX(-1)'}}/>}>
                        Go to Patron Booking Page
                    </Button>
                     
                    {this.state.isBusy ? this.getBusyComponent('right', {marginLeft: 20}, 30) : null}

                </div>
                
                <div style={{marginTop: 15}}/>
                
                {sp ?

                    <Fragment>
        
                        <Paper style={this.styles.paper}>   

                            <Tooltip title={this.state.status.tooltip}>
                                <div style={{...this.styles.status, backgroundColor: this.state.status.color}}>
                                        {this.state.status.label}
                                </div>
                            </Tooltip>
                            
                            <Typography style={this.styles.paperLabel} variant="h6">{"Editing \"" + this.state.selectedService.name + "\""}</Typography> 
                            
                            <Grid container spacing={2}>

                                <Grid item xs={12} lg={6}>
                                    
                                    <Typography variant="body2" style={this.styles.sectionLabel}>Patron Availability</Typography>            
                                    <div style={this.styles.roundedContainer}>
                                        <Typography variant='caption' align='right' style={{display: 'flex', justifyContent: 'right', color: 'gray', fontStyle: 'italic', marginLeft: 'auto'}}>{"Timezone: " + Global.getTimezone()}</Typography>                        
                                        
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginBottom: 10}}>
                                            <Tooltip title="When unchecked, Patrons cannot book the Service (but you can manually create Bookings)">
                                                <Checkbox defaultChecked={sp.enabled} color='primary'
                                                        onChange={(event) => { sp.enabled = event.target.checked; }}/>       
                                            </Tooltip>                           
                                            <Typography variant='body1' align='left' style={{width: 200}}>Enable Patron Bookings</Typography>                        
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5, height: 50}}>
                                            <Tooltip title="When checked, the Service is closed to Patrons before this date. Otherwise, Patrons can access the Booking Portal anytime">
                                                <Checkbox checked={this.state.seasonBookingAutoEnableChecked} color='primary'
                                                          onChange={(event) => this._dateBoxChecked("seasonBookingAutoEnable", event)}/>                                  
                                            </Tooltip>
                                            <Typography variant='body1' style={{width: 200}} align='left'>Service Opens After</Typography>
                                            <div style={{maxWidth: 400, display: this.state.seasonBookingAutoEnableChecked ? 'block' : 'none'}}>
                                                <LuxonDatePicker label='Open' timezone={Global.getTimezone()} 
                                                                initialDate={sp.seasonBookingAutoEnable}
                                                                onDateChange={(date) => sp.seasonBookingAutoEnable = date} onParseError={this._onDateParseError} /> 
                                            </div>
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5, height: 50}}>
                                            <Tooltip title="When checked, this is the earliest time that can be booked.">
                                                <Checkbox checked={this.state.seasonBookingStartChecked} color='primary'
                                                          onChange={(event) => this._dateBoxChecked("seasonBookingStart", event)}/>                                  
                                            </Tooltip>  
                                            <Typography variant='body1' align='left' style={{width: 200}}>First available Booking</Typography>
                                            <div style={{maxWidth: 400, display: this.state.seasonBookingStartChecked ? 'block' : 'none'}}>
                                                <LuxonDatePicker label='Start' timezone={Global.getTimezone()} 
                                                                initialDate={sp.seasonBookingStart}
                                                                onDateChange={(date) => sp.seasonBookingStart = date} onParseError={this._onDateParseError} /> 
                                            </div>
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5, height: 50}}>
                                            <Tooltip title="When checked, this is the latest time bookings can be scheduled for (they may not start after this time)">
                                                <Checkbox checked={this.state.seasonBookingEndChecked} color='primary'
                                                          onChange={(event) => this._dateBoxChecked("seasonBookingEnd", event)}/> 
                                            </Tooltip>                                 
                                            <Typography variant='body1' align='left' style={{width: 200}}>Last Booking Before</Typography>
                                            <div style={{maxWidth: 400, display: this.state.seasonBookingEndChecked ? 'block' : 'none'}}>
                                                <LuxonDatePicker label='End' timezone={Global.getTimezone()} 
                                                                initialDate={sp.seasonBookingEnd}
                                                                onDateChange={(date) => sp.seasonBookingEnd = date} onParseError={this._onDateParseError} /> 
                                            </div>
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5, height: 50}}>
                                            <Tooltip title="When checked, only Members can book the Service (Members must supply a barcode or Membership ID when booking and Membership must not be suspended or expired)">
                                                <Checkbox defaultChecked={sp.requiresMembership} color='primary'
                                                          onChange={(event) => sp.requiresMembership = event.target.checked}/> 
                                            </Tooltip>                                 
                                            <Typography variant='body1' align='left' style={{width: 200}}>Requires Membership</Typography>
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5, height: 50}}>
                                            <Tooltip title="Requires the Service Owner or a Resource Manager to approve the Booking (Patron is not charged until approved)">
                                                <Checkbox defaultChecked={sp.requiresApproval} color='primary'
                                                          onChange={(event) => sp.requiresApproval = event.target.checked}/> 
                                            </Tooltip>                                 
                                            <Typography variant='body1' align='left' style={{width: 200}}>Requires Approval</Typography>
                                        </div>


                                        

                                    </div>
                                </Grid>


                                <Grid item xs={12} lg={6}>

                                    <Typography variant="body2" style={this.styles.sectionLabel}>Hours of Operation</Typography>
                                    <div style={this.styles.roundedContainer}>

                                        {this._dayHoursOfOperation(0)}
                                        {this._dayHoursOfOperation(1)}
                                        {this._dayHoursOfOperation(2)}
                                        {this._dayHoursOfOperation(3)}
                                        {this._dayHoursOfOperation(4)}
                                        {this._dayHoursOfOperation(5)}
                                        {this._dayHoursOfOperation(6)}

                                    </div>
                                </Grid>

                                <Grid item xs={12} lg={7}>

                                    <Typography variant="body2" style={this.styles.sectionLabel}>Booking Slot Times and Scheduling</Typography>
                                        <div style={this.styles.roundedContainer}>

                                            <div style={{display: 'flex', flexDirection: 'row', gap: 40, marginTop: 20}}>
                                                <div style={{display: 'flex', flexDirection: 'column', gap: 10, width: 340}}>

                                                    <Autocomplete size='small'
                                                                    style={{width: 300, marginTop: 5, marginLeft: 15, marginBottom: 20}}
                                                                    value={sp.incrementsMinutes}
                                                                    onChange={(event, newValue) => { this._serviceParamsFieldChanged("incrementsMinutes", newValue) }}
                                                                    options={ServiceParams.IncrementsMinutes}
                                                                    getOptionLabel={(option) => (option < 120 ? (option + " minutes") : (option / 60) + " hours")}
                                                                    blurOnSelect
                                                                    renderInput={(params) => <TextField {...params} label="Slot Interval" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                                                /> 
 

                                                    <ManageNumericField fullWidth={false} hasInfinity={false} justify='left' 
                                                                        tooltip="The maximum number of intervals that can be requested for a single Booking"
                                                                        label="Max Intervals" minValue={1}
                                                                        json="maxIncrements"
                                                                        initialValue={sp.maxIncrements} 
                                                                        onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/>     


                                                    <ManageNumericField hasInfinity={false} justify='left' 
                                                                        tooltip="Amount of time to leave free after a Booking before the next Booking can be scheduled. The Patron will see the time shortened by this amount."
                                                                        label="Post Booking Buffer (minutes)" minValue={0} increment={5}
                                                                        json="postBookingBufferMinutes"
                                                                        initialValue={sp.postBookingBufferMinutes} 
                                                                        onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/>  
                                                </div>

                                                <div>
                                                    {this.state.usesSlotCalendar ?
                                                        <Autocomplete size='small'
                                                                        style={{width: 300, marginTop: 5, marginBottom: 20}}
                                                                        value={sp.schedulingInterval}
                                                                        onChange={(event, newValue) => { sp.schedulingInterval = newValue; }}
                                                                        options={ServiceParams.SchedulingIntervals}
                                                                        disabled={!this.state.usesSlotCalendar}
                                                                        getOptionLabel={(option) => {
                                                                            switch (option) {
                                                                                case 0: return "On Slot Intervals";
                                                                                case 30: return "On the half hour";
                                                                                case 60: return "Top of the hour";
                                                                                default: return "Unknown";
                                                                            }
                                                                        }}
                                                                        blurOnSelect
                                                                        renderInput={(params) => <TextField {...params} label="Scheduling Start" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                                                    />  
                                                    : null}

                                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                                        <Checkbox defaultChecked={sp.allowOverlap} color='primary'
                                                                  onChange={(event) => sp.allowOverlap = event.target.checked}/>  
                                                        <Typography variant='body1' align='left'>Allow Overlapping Bookings</Typography>                        
                                                    </div>    

                                                </div>
                                            </div>
                                        </div>


                                </Grid> 

                                <Grid item xs={12} lg={5}>

                                    <Typography variant="body2" style={this.styles.sectionLabel}>Resources</Typography>
                                    <div style={this.styles.roundedContainer}>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                            <Tooltip title="When checked, the Service must have at least one Resource that is available during the booking time">
                                                <Checkbox defaultChecked={sp.requireAvailableResource} color='primary'
                                                        onChange={(event) => sp.requireAvailableResource = event.target.checked}/>  
                                            </Tooltip>
                                            <Typography variant='body1' align='left'>Require Available Resource</Typography>                        
                                        </div>   

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                            <Tooltip title="When checked, the Patron can select among available Resources. Otherwise, one will be randomly assigned.">
                                                <Checkbox defaultChecked={sp.allowPatronToSelectResource} color='primary'
                                                        onChange={(event) => sp.allowPatronToSelectResource = event.target.checked}/>  
                                            </Tooltip>
                                            <Typography variant='body1' align='left'>Allow Patron to Select Resource</Typography>                        
                                        </div>   
                                    </div> 
                                </Grid>
                                
                                <Grid item xs={12} lg={7}>

                                    <Typography variant="body2" style={this.styles.sectionLabel}>Fees</Typography>
                                    <div style={this.styles.roundedContainer}>

                                        <Typography variant='caption' align='right' style={{display: 'flex', justifyContent: 'right', color: 'gray', fontStyle: 'italic', marginLeft: 'auto'}}>All costs are calculated and added to the total in the order below.</Typography>                        
    
                                        <div style={{width: 340, marginBottom: 10}}>

                                            <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                                                tooltip="Cost per each increment of time booked."
                                                                label={"Cost per Increment (" + currency + ")"} minValue={0}
                                                                decimalPlaces={2}
                                                                json="costPerIncrement"
                                                                initialValue={sp.costPerIncrement} 
                                                                onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/>  
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', width: 720}}>

                                            <div style={{width: 340}}>
                                                <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                                                    tooltip={"Cost per patron for the entire Booking"}
                                                                    label={"Cost per patron (" + currency + ")"} minValue={0}
                                                                    decimalPlaces={2}
                                                                    json="costPerPatron"
                                                                    initialValue={sp.costPerPatron} 
                                                                    onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/> 
                                            </div>

                                            <ManageNumericField fullWidth={false} hasInfinity={false} justify='left' 
                                                                tooltip="Maximum number of Patrons who can attend the Booking (ignored when Cost per Patron is zero)"
                                                                label="Max Patrons per Booking" minValue={1}
                                                                json="maxPatronCount"
                                                                initialValue={sp.maxPatronCount} 
                                                                onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/>  
                                        </div>       

                                        {sp.additionalCosts.map((item, index) => this._additionalCostItem(item, index))}

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
                                            <Button size='small' variant='outlined' style={{color: ThemeColors.addColor}} onClick={this._addAdditionalCostItem} color='primary'>Add Optional Cost Item</Button>
                                        </div>

                                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 10, gap: 10, maxWidth: 300}}>

                                            <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                                                tooltip="Add a Service fee as a percentage to the total cost calculated above"
                                                                label={"Service Fee %"} minValue={0}
                                                                decimalPlaces={1}
                                                                json="serviceFeePercent"
                                                                initialValue={sp.serviceFeePercent} 
                                                                onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/>  

                                            <ManageDecimalField fullWidth={false} hasInfinity={false} justify='left' 
                                                                tooltip="Add a fixed Service fee to the total cost calculated above"
                                                                label={"Fixed Service Fee (" + currency + ")"}  minValue={0}
                                                                decimalPlaces={2}
                                                                json="serviceFeeFixed"
                                                                initialValue={sp.serviceFeeFixed} 
                                                                onFieldChange={this._serviceParamsFieldChanged} changedBackgroundColor='white'/> 
                                        </div>   

                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 5, marginTop: 10, justifyContent: 'start'}}>
                                            <Tooltip title="Add an additional Service fee that matches your AGS payment processing fee, effectively passing our fee to you on to your Patrons. Only available if your Marketplace operates in USD.
                                                     This fee is applied all other fees above. The fee is not applied if the order total is zero.">         
                               
                                                <Checkbox defaultChecked={sp.addAGSServiceFee} color='primary'
                                                            onChange={(event) => this.state.selectedService.serviceParams.addAGSServiceFee = event.target.checked}/>  
                                            </Tooltip>
                                            <Typography variant='body1' align='left'>Add Matching AGS Service Fee</Typography>                        
                                        </div>  

                                       

                                    </div>
                                </Grid>

                                <Grid item xs={12}>

                                    <Typography variant="body2" style={this.styles.sectionLabel}>Patron Instructions and Terms & Conditions</Typography>
                                    <div style={this.styles.roundedContainer}>

                                        <RichTextEditor draftContent={sp.termsAndConditions}     
                                                editing={true}  
                                                onChange={ (draftJson) => {
                                                    this._serviceParamsFieldChanged("termsAndConditions", draftJson);
                                                }}/>
                                    </div>
                                </Grid>     

                            </Grid>

 
                        </Paper>
                    </Fragment>                
                    : null
                }
                    
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(ServicesPage));




