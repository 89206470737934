
import React, { Fragment, useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';

import { ColorPicker } from 'react-frontend-utils';
import { Service } from '../models/Service';


// This component is used to display the color options for a resource. The resource is modified in place. When the user changes color, the onChange function is called.
//
// User must pass the following props:
// resource: the resource object to be modified
// onChange: a function with no arguments to be called when the color is changed
// resetColorCallback: a function with no arguments to be called when the user clicks the "Reset Color" button

export function ResourceColorActions(props) {

    const [color, setColor] = useState(props.resource.color);

    const onColorChange = (color) => {
        setColor(color.hex);
    }

    const onColorClose = () => {
        if (props.resource.color !== color) {
            props.resource.color = color;
            props.onChange();
        }
    }

    const enabled = props.resource.canEdit();

    const items = props.resource instanceof Service ? "Bookings" : "Events";

    return ( 
            <Fragment>                    
                
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, marginBottom: 10}}>
                    <Tooltip title={"Default Color for new Calendar " + items}>
                        <div>
                            <ColorPicker initialColor={color} disableAlpha={true} onChange={onColorChange} onClose={onColorClose} disabled={!enabled}/>
                        </div>
                    </Tooltip>
                    <Tooltip title={"Reset the color all " + items + " on this " + props.resource.type + " to the default color"}>
                        <Button size='small' variant='outlined' style={{marginTop: -3}} onClick={props.resetColorCallback} disabled={!enabled}>
                            {"Reset " + items + " Color"}
                        </Button>
                    </Tooltip>
                </div>
            </Fragment>
            );
}
