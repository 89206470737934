import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles'
import { AppBar, Toolbar, Typography, Button, Container, Grid } from '@material-ui/core'

import BookServicePage1 from '../patron/BookServicePage1'
import BookServicePage2 from '../patron/BookServicePage2'
import BookServicePage3 from '../patron/BookServicePage3'

import { Global } from '../models/Global'
import { Service } from '../models/Service'
import { Resource } from '../models/Resource'
import { Booking } from '../models/Booking'
import AGTheme, { ThemeColors } from '../Theme'
import { logo } from '../App'
import { DateConversions } from '../utils/DateConversions'

import { PatronSelectResourcePopover } from '../components/PatronSelectResourcePopover'

import { RestComponent, HomepageContext } from 'react-frontend-utils'



/**
 * Landing page for the /patron/booking route.  This page is the entry point for a Patron to book a Service. They must arrive with a database parameter in the query string
 * and optionally a serviceid parameter to select a specific service.  If there is only one service, or the serviceid is provided, the page will go directly to the BookServicePage1 
 * for that service. Otherwise, a list of available services along with their descriptions will be displayed for the Patron to select from.
 * 
 * The timezone for the community is fetched first, then the services are fetched. Once a service is selected (or if just one is available), BookingPage1 is loaded.
 * The back button takes them back to the list of services (if there is more than one).
 * 
 * If the Patron selects a booking time, we verify with the server that the slots are free, allow them to select a resource or verify one is available, then
 * proceed to BookServicePage2. If it is no longer available, we do not allow the booking to proceed. Prior to loading BookServicePage2, the slot is held on the server
 * with the status "CREATED".
 * 
 */
class PatronBooking extends RestComponent {
  
    styles = {       
        divider: {
            marginTop: 10, 
            marginBottom: 20, 
            border: '2px solid gray'
        },
        link : {
            display: 'flex', 
            justifyContent: 'center', 
            color: 'white',
            textDecoration: 'underline',
            fontSize: 14
        },
        appTitle: {
            marginLeft: 10,
            textShadow: "2px 2px #333333",
            fontWeight: "bold",
            color: 'white',
            textAlign: 'left',
            flexGrow: 1,   //fill all space to push other elements to the right edge
            textDecoration: 'none',
            cursor: 'pointer'
        },
        servicesMsg: {
            marginTop: 20,
            textAlign: 'center',
            color: 'gray',
            fontSize: "150%",
        },
    };
    
    
    _database;
    _extendedName;
    _serviceid;
    _timezone;

    constructor(props) {
        super(props);
        this.state.isMobile = false;
        this.state.isVerySmall = false;
        this.state.didLoad = false;

        this.state.services = [];
        this.state.serviceid = null;

        this.state.selectedService = null;
        this.state.selectedBooking = null;
        this.state.reservedBooking = null;
        this.state.reservedResource = null;
        this.state.showSelectResourcePopover = false;
        this.state.availableResources = [];
        this.state.agsServiceFee = null;

        this.state.complete = false;
        
        this._database = props.database ? decodeURIComponent(props.database) : null;  //Arrives with the query string set to the desired database
        this._serviceid = props.serviceid ? encodeURIComponent(props.serviceid) : null;  //encode for URL safe
    }


    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        this._fetchTimezone();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    _rootPath() {
        return "/patron/booking?database=" + this._database;
    }


    //callback when window changes size
    _updateSize = () => {
        this.setState({isMobile: window.innerWidth < 916, isVerySmall: window.innerWidth < 476 });  //custom, split between bootstrap and mui
    }

    _fetchTimezone = () => {
        if (!this._database) {
            this.setState({didLoad: true});
            return;
        }

        // Fetch the timezone for the current database
        this.incrementBusy();
        this.secureJSONFetch("/tz/" + this._database, {}, this._fetchTimezoneCallback, this._fetchErrorCallback);
    }
    
    // When the timezone is fetched, update the timezone or use the default, and then fetch the services
    _fetchTimezoneCallback = (response) => {
        this.decrementBusy();
        if (response) {
            if (response.group !== this._database) {
                this.showConfirmAlert("Error", "Timezone response for wrong database", 'red');
                return;
            }
            if (!response.timezone)
                Global.setTimezone(Global.DEFAULT_TIMEZONE);
            else
                Global.setTimezone(response.timezone);

            this._extendedName = response.extendedGroupName;

            console.log("Timezone set to: " + Global.getTimezone());

            this._fetchServices();   
        }
    }
  
 
    //Called after mounting the page
    _fetchServices = () => {
        this.setBusy(true);
        const query = this._serviceid ? ("?id=" + this._serviceid) : "";
        this.secureJSONFetch("/patron/" + this._database + "/services" + query, {}, this._fetchServicesCallback, this._fetchErrorCallback); 
    }


    //Callback for fetching the services, response is a list of services
    _fetchServicesCallback = (response) => {
        this.setBusy(false);
        if (response) {

            const services = response.map((r) => {
                if (r.type && r.type === "Service")
                    return new Service(r, Global.getTimezone());
                else {
                    console.error("Invalid service response: not a Service");
                    return null;
                }
            });

            // Sort services by name
            services.sort((a, b) => a.name.localeCompare(b.name));

            // Set all services, no service selected
            this.setState({services: services, serviceid: null});

            // If there is only one service, goto the booking page for that service
            if (services.length === 1)
                this._selectService(services[0]);

        } 
        this.setState({didLoad: true});

        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (userTimezone !== Global.getTimezone())
            this.showConfirmAlert("Timezone Mismatch", "Your browser timezone is " + userTimezone + ", but your community uses " + Global.getTimezone() + ". You will view " +
                                  "the calendar in your community's timezone. Please be aware of the difference when booking.", 'red');
    }

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }

    _selectService = (service) => {  
        this.props.history.push(this._rootPath() + "&serviceid=" + service.id);
        this.setState({selectedService: service});
    }


    // patron pressed the back button while on BookServicePage1
    _returnFromServiceBookingPage1 = () => {
        this.props.history.push(this._rootPath());
        this.setState({selectedService: null, selectedBooking: null, reservedBooking: null, reservedResource: null, agsServiceFee: null, complete: false});
    }

    // patron pressed the Booking button on BookServicePage1, check if still available
    _proceedToCheckServiceBooking = (booking) => {

        const bookingStart = DateConversions.luxonDateTimeToJsonDateString(booking.start);
        const bookingEnd = DateConversions.luxonDateTimeToJsonDateString(booking.end);

        // Determine if the booking is available
        this.incrementBusy();
        this.secureJSONFetch("/patron/services/" + this.state.selectedService.id + "/checkBookingAvailability?fromDate=" + bookingStart + "&toDate=" + bookingEnd, {}, 
                             (response) => this._checkBookingAvailable(booking, response), this._fetchErrorCallback); 
    }


    // Called when the booking is no longer available after checking for such, or if the user cancels selecting a resource
    _bookingUnavailableOrCancelled = () => {
        this.setState({reservedBooking: null, reservedResource: null, selectedBooking: null, selectedService: null, agsServiceFee: null, complete: false,
                      didLoad: false, showSelectResourcePopover: false, availableResources: []});
        this._fetchServices();  
    } 


    _checkBookingAvailable = (booking, response) => {
        this.decrementBusy();

        // If there is a response, the booking is available. The response is a list of available resource ids. An empty list means no resources are required.
        if (response) {

            if (this.state.selectedService.serviceParams.requireAvailableResource && this.state.selectedService.serviceParams.allowPatronToSelectResource) {

                const availableResources = response.map((r) => new Resource(r));
                
                // If there are no available resources, something went wrong
                if (availableResources.length === 0) {
                    this.showConfirmAlert("Error", "Something changed on a Service's Resource since the page was loaded. Please try again.", 'red');
                    this._bookingUnavailableOrCancelled();
                    return;
                }

                // Show a popup to select a resource
                console.info("Available resources for Patron to Select: ", availableResources);
                this.setState({selectedBooking: booking, showSelectResourcePopover: true, availableResources: availableResources});
                return;
            }

            // No resources required, so proceed to Book it
            console.log("Booking is available, resource will be auto-assigned");
            this.setState({selectedBooking: booking});
            this._reserveBooking();
        }
        else {
            // Booking is not available
            this.showConfirmAlert("Error", "The selected booking time is no longer available. Please select another time.", 'red');
            this._bookingUnavailableOrCancelled();
        }
    }


    _reserveBooking = (resourceId) => {

        this.setState({showSelectResourcePopover: false});

        const bookingStart = DateConversions.luxonDateTimeToJsonDateString(this.state.selectedBooking.start);
        const bookingEnd = DateConversions.luxonDateTimeToJsonDateString(this.state.selectedBooking.end);

        const requestResource = resourceId ? ("&resourceId=" + resourceId) : "";

        this.incrementBusy();
        this.secureJSONFetch("/patron/services/" + this.state.selectedService.id + "/reserveBooking?fromDate=" + bookingStart + "&toDate=" + bookingEnd + requestResource, 
                            {method: 'POST'}, this._reserveBookingCallback, this._fetchErrorCallback); 
    }


    _reserveBookingCallback = (response) => {
        this.decrementBusy();
        if (response) {
            const booking = new Booking(response.booking);  // server gives us back the actual Booking
            const resource = new Resource(response.resource);  // server gives us back the actual Resource
            const agsServiceFee = {serviceFeeMultiplier: response.serviceFeeMultiplier, serviceFeeFixed: response.serviceFeeFixed};
            this.setState({reservedBooking: booking, reservedResource: resource, agsServiceFee: agsServiceFee});    
            console.log("Booking reserved: ", booking);

            this.props.history.push(this._rootPath() + "&serviceid=" + this.state.selectedService.id + "&bookingid=" + booking.id);
        }
        else {
            this.showConfirmAlert("Error", "The selected booking time could not be reserved. It is either no longer available or the Service has changed. Please select another time.", 'red');
            this._bookingUnavailableOrCancelled();
        }
    }


    _returnFromServiceBookingPage2 = () => {
        this.props.history.push(this._rootPath() + "&serviceid=" + this.state.selectedService.id);

        this.incrementBusy();
        this.secureJSONFetch("/patron/booking/" + this.state.reservedBooking.id + "/cancel", 
                            {method: 'POST'}, (response) => this.decrementBusy, this._fetchErrorCallback); 

        this._bookingUnavailableOrCancelled();
    }

    _finishedBooking = () => {
        console.log("Booking complete");
        this.props.history.push(this._rootPath() + "&serviceid=" + this.state.selectedService.id + "&bookingid=" + this.state.reservedBooking.id + "&completed=true");
        this.setState({complete: true});
    }

    render() {

        //Selects the current page to view
        const viewingPage = (() => {
                       
            if (!this.state.didLoad)
                return <div style={this.styles.servicesMsg}>Loading...</div>;

            if (!this._database)
                return <div style={this.styles.servicesMsg}>You did not arrive with a link from your community</div>;

            if (this.state.services.length === 0)
                return <div style={this.styles.servicesMsg}>We're sorry, your community does not currently have any Services available.</div>;


            if (this.state.selectedService) {

                if (this.state.complete)
                    return <BookServicePage3 service={this.state.selectedService} booking={this.state.reservedBooking} resource={this.state.reservedResource} 
                                             prevPageCallback={this._returnFromServiceBookingPage1}/>;


                if (this.state.reservedBooking)  // patron selected a booking time
                    return <BookServicePage2 database={this._database} service={this.state.selectedService} booking={this.state.reservedBooking}
                                             resource={this.state.reservedResource} agsServiceFee={this.state.agsServiceFee}
                                             prevPageCallback={this._returnFromServiceBookingPage2}
                                             nextPageCallback={this._finishedBooking}/>;
                

                // Service selected, so go select a booking time
                return <BookServicePage1 database={this._database} service={this.state.selectedService} 
                                         prevPageCallback={this._returnFromServiceBookingPage1}
                                         nextPageCallback={this._proceedToCheckServiceBooking}/>;
            }
            
            //List of Available Services to select from
            return (
                <Fragment>
                    <div style={{...this.styles.servicesMsg, color: ThemeColors.appBarBackground}}>Select a Service to Book:</div>
                        
                    <Grid container spacing={2} style={{marginTop: 20}}>
                        {this.state.services.map((service) =>               
                            <Fragment key={service.id}>
                                <Grid item xs={12} sm={6}>
                                    <Button fullWidth variant="outlined" color="primary" onClick={() => this._selectService(service)}>{service.name}</Button> 
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography style={{color: 'gray', fontStyle: 'italic', marginBottom: 40}}>{service.description}</Typography>
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                </Fragment>);

        })();
        
        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;
      
        const gutterMargin = 20;
        
        const headerFontSize = this.state.isMobile ? "120%" : "170%";
               
        return (
            <HomepageContext.Provider value={{sessionExpiredCallback: this.sessionExpired}}>
    
                <ThemeProvider theme={AGTheme}>
                    <Fragment>

                        {this.getConfirmAlertComponent()  /*inject an alert component*/}  

                        <AppBar position="static" style={{marginBottom: 12, backgroundColor: ThemeColors.appBarBackground}}>
                            <div style={{paddingTop: 0, paddingBottom: 4, paddingLeft: gutterMargin, paddingRight: gutterMargin}}>

                                <Toolbar disableGutters={true}>
                                     {logo}
                                     <Typography variant="h5" onClick={this._returnFromServiceBooking} style={{...this.styles.appTitle, fontSize: headerFontSize}}>Patron Booking</Typography>    
                                     <Typography variant="h5" style={{...this.styles.appTitle, textAlign: 'right', fontStyle: 'italic', fontSize: headerFontSize}}>{this._extendedName}</Typography>    
                                </Toolbar>
                            </div>
                        </AppBar>


                        {serverErrorMessage}

                        <PatronSelectResourcePopover isOpen={this.state.showSelectResourcePopover} resources={this.state.availableResources}
                                                     cancelCallback={this._bookingUnavailableOrCancelled} okCallback={(resourceId) => this._reserveBooking(resourceId)}/>
                        
                        <Container maxWidth={false} disableGutters={true} style={{paddingLeft: gutterMargin, paddingRight: gutterMargin}}>
                            {viewingPage} 
                        </Container>

                    </Fragment>
                </ThemeProvider>    
            </HomepageContext.Provider>
        );
    }
};

export default withCookies(withRouter(PatronBooking));


